.school-program-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px 80px;
  overflow: hidden;

  .school-program-card__image {
    background-position: center;
    background-repeat: none;
    background-size: cover;
    position: relative;

    button {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }

  .school-program-card__content {
    padding: 1rem;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}