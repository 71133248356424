@import 'src/styles/variables';

.profile-icon-button-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    background: transparent;
    border: none;
    padding: 0;
    border-radius: 50%;
    margin-left: 1rem;

    &:hover {
      background: $lime;
    }
  }
}