@import 'src/styles/variables';
@import 'src/styles/text';

.inst-pricing-models {
  padding-bottom: 2rem;

  .intro {
    margin: 1rem 0 3rem;
    
    h2 {
      margin-bottom: 1rem;
    }
  }

  .gray {
    color: $dark-gray;

    strong {
      color: $almost-black;
      font-weight: normal;
    }
  }

  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
  }

  .section {
    margin: 1rem 0;
    border-radius: 8px;

    > div {
      padding: 0 4rem;
    }

    &--green {
      background: $inst-light-green;
      padding: 0.5rem;

      > div:first-child {
        border-right: 1px solid $inst-green;
      }
    }

    &--white,
    &--outline {
      padding: 4rem 0.5rem;
    }

    &--white {
      align-items: center;

      ul {
        padding-left: 1.5rem;
        @extend .inst-text-p;
        color: $dark-gray;
      }
    }

    &--outline {
      border: 4px solid $inst-green;

      .inst-text-p-small {
        max-width: 300px;
      }
    }

    .cost {
      @extend .inst-text-title;
      text-transform: uppercase;
    }
  }

  hr {
    border: none;
    height: 2px;
    background: $inst-light-green;
    margin: 2rem 0;
  }

  .center {
    text-align: center;
  }

  .space-y {
    margin: 3rem 0;
  }

  .estimates-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);

    > div:not(.heading) {
      padding: 3rem 2rem;
    }

    &.subgrid > div:not(.row1) {
      border-top: 1px solid $medium-gray;
    }

    .total {
      border-top: 1px solid $medium-gray;

      .cost {
        @extend .inst-text-h4;
        color: $inst-green;
      }

      &.green > .cost {
        @extend .inst-text-h1;
        color: $inst-dark-green;
      }
    }
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .heading {
    @extend .inst-text-label;
    text-align: center;
    padding: 2rem;
  }

  .green {
    background-color: $inst-light-green;

    input[type=number] {
      width: 120px;
      height: 51px;
      border: 2px solid $inst-green;
      border-radius: 8px;
      background: white;
      @extend .inst-text-h4;
      color: $inst-green;
      text-align: center;
    }

    label {
      @extend .inst-text-h4;
    }

    &.heading {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &.total,
    &.last-row {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .flex {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 1rem;
    }
  }

  .flex-baseline {
    display: flex;
    align-items: baseline;
  }

  .info-btn {
    background: transparent;
    border: none;
    padding: none;
    align-self: flex-start;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-center;

    > div:not(.heading) {
      padding: 2rem 2rem;
    }

    .col-1 {
      @extend .inst-text-h4;
    }

    .or {
      @extend .inst-text-label;
      color: $dark-gray;
      position: relative;
      top: 2rem;
    }

    .flex-center p:not([class]) {
      @extend .inst-text-p;
      color: $dark-gray;

      span {
        color: black;
      }
    }

    &.tight {
      > div {
        padding: 0.5rem 2rem;
      }

      .last {
        padding-bottom: 2rem;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      > div:not(.inst-text-h4) {
        @extend .inst-text-p;
      }
    }

    .cost {
      @extend .inst-text-h4;
    }
  }

  .bullets-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: flex-start;
    column-gap: 2rem;
    row-gap: 2rem;

    p {
      @extend .inst-text-h4;
      text-align: right;
    }

    ul {
      padding: 0 0 0 1.5rem;
      margin: 0;
      @extend .inst-text-p;
    }
  }

  .leads-grid {
    display: grid;
    grid-template-columns: 1.5fr repeat(4, 1fr);

    .inst-text-h1 {
      color: $inst-green;
    }

    .flex-center p {
      @extend .inst-text-p-small;
      color: $dark-gray;

      span {
        color: black;
      }
    }

    .gray-col {
      background: $light-gray;

      &.heading {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    > div {
      padding: 1rem 0;
      max-height: 60px;

      &.space {
        padding: 3rem 0;
        height: auto;
      }
    }

    .col-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @extend .inst-text-p;
    }

    .last {
      padding-bottom: 2rem;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}

.inst-pricing-popup {
  padding: 1.5rem;
  @extend .inst-text-p-small;

  p, ul {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}