@import 'src/styles/variables';
@import 'src/styles/text';

.inst-peer-programs {

  &__content {
    padding: 0 calc(2 * #{$student-main-padding-x}) 0 $student-main-padding-x;

    p {
      @extend .inst-text-p;
    }

    .section {
      margin: 2rem 0;

      &__header {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        h2 {
          @extend .inst-text-h2;
        }
      }
    }
  }

  .stats-grid,
  .stats-grid-2,
  .underline-grid {
    display: grid;

    &:not(.row1) {
      padding: 1.5rem 0;
    }

    .header {
      @extend .inst-text-label-small;
    }

    .col1 {
      @extend .inst-text-h4;
      display: flex;
      align-items: center;

      img {
        width: 1rem;
        margin-right: 1rem;
      }
    }

    .green-stat {
      background: $inst-light-green;
      @extend .inst-text-h2;
      padding: 0.5rem 1rem;
      min-width: 88px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin: 0.5rem 0;
        padding-right: 4rem;

        .green-stat {
          width: 100%;
          align-items: flex-start;
          @extend .inst-text-h4;
        }
      }
    }
  }

  .stats-grid,
  .stats-grid-2 {
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 1rem 0;
    align-items: center;

    .col1 {
      padding-left: 2rem;
    }
  }

  .stats-grid {
    grid-template-columns: auto 150px 150px;
  }

  .stats-grid-2 {
    grid-template-columns: auto calc(300px + 1rem);
  }

  .underline-grid-container {
    padding: 0 2rem;
  }

  .underline-grid {
    grid-template-columns: auto 150px 150px;
    column-gap: 0;
    row-gap: 0;

    &.row1 .header {
      padding: 1rem 0;
    }

    &:not(.row1) {
      > div:not(.last-row) {
        border-bottom: 2px solid $inst-light-green;
      }

      > div {
        padding: 1rem 0;
      }
    }
  }

  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }

  .grid-2,
  .grid-3 {
    .green-box,
    .gray-box {
      padding: 2rem;
      height: 280px;

      > * {
        margin-bottom: 1rem;
      }

      h3 {
        @extend .inst-text-h2;
      }

      p:not(.coming-soon) {
        @extend .inst-text-h4;
      }

      .coming-soon {
        @extend .inst-text-p;
        font-style: italic;
      }
    }

    .green-box {
      background-color: $inst-light-green;
    }

    .gray-box {
      background-color: $light-gray;
    }

    .inst-favorited-by {
      display: inline-block;
      @extend .inst-text-label-small;
      padding: 0.5rem 1rem;
      border-radius: 58px;
      margin-bottom: 1rem;

      &.green {
        background: $inst-dark-green;
        color: $inst-light-green;
      }

      &.gray {
        background: $dark-gray;
        color: $light-gray;
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
    color: $inst-dark-pink;
    
    button {
      color: $inst-dark-pink;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      cursor: pointer;
      
      &.inst-text-p {
        text-decoration: underline;
      }
      
      img {
        height: 20px;
        margin-right: 0.5rem;
        
        &.carat {
          height: 10px;
          margin-left: 0.5rem;
          
          &.reverse {
            transform: rotate(180deg);
          }
        }
      }
    }
    
    .student-filter-wrapper {
      position: relative;
      
      .students-dropdown {
        position: absolute;
        z-index: 2;
        right: 1.5rem;
        top: 2rem;
        
        .shadow-card {
          background: white;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          width: 17rem;
          
          button {
            color: black;
            text-decoration: none;
            margin-top: .5rem;
            
            &.bold {
              font-weight: 800;
            }
          }
        }
      }
    }
    
    .time-filter-wrapper {
      position: relative;
      
      .time-dropdown {
        position: absolute;
        z-index: 2;
        background: white;
        right: 1rem;
        top: 2rem;
        
        .shadow-card {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          width: 12rem;
          
          button {
            color: black;
            text-decoration: none;
            margin-top: .5rem;
            
            &.bold {
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}

