@import 'src/styles/variables';
@import 'src/styles/text';

.inst-edit-button {
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;
  @extend .inst-text-p-small;

  img {
    margin-left: 0.5rem;
  }

  &--custom {
    @extend .inst-cta-link;
    padding: 0;
    margin-top: 1rem;

    img {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
}

.inst-coming-soon-modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 1rem;
  }
}