@import 'src/styles/text';

.student-careers__content{
    padding: 5rem 2rem 3rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > * {
        max-width: 60%;
        margin-bottom: 1rem;
    }

}