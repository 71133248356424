@import 'src/styles/variables';

.tips-and-best-practices-roi {

  .back-link {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  
  .banner-image {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    object-position: 0 100%;
  }
  
  .article-content {
    padding: 2rem;
    
    h1 {
      margin-bottom: 1rem;
    }
    
    span {
      color: gray;
    }
    
    ul {
      margin-top: 1rem;
      list-style-type: disc;
      padding-left: 2rem;
    }
  }
}