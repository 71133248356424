@import 'src/styles/variables';
@import 'src/styles/text';

.student-onboarding-form {
  max-width: 750px;

  button.btn--delete {
    background: transparent;
    border: none;
    padding: none;
    transform: translateY(25%);
  }

  .coming-soon {
    @extend .student-text-p-small-italic;
    color: $dark-pink;
  }

  .field,
  fieldset {
    margin: 2rem 0;
  }

  fieldset {
    padding: 0;
    border: none;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  label:not(.checkbox, .radio, .slider),
  legend {
    @extend .student-text-h1;
  }

  .slider {
    @extend .student-text-label;
  }

  .checkbox,
  .radio {
    margin-right: 1rem;

    [type=checkbox],
    [type=radio] {
      position: absolute;
      top: 0;
      left: 0;
      appearance: none;
      width: 0.001px;
      height: 0.001px;
    }

    [type=checkbox] + span,
    [type=radio] + span {
      @extend .student-text-h3;
      background: $light-gray;
      border: 2px solid transparent;
      border-radius: 31px;
      padding: 0.5rem 1.5rem;
    }

    &:focus-within {
      [type=checkbox] + span,
      [type=radio] + span {
        border: 2px solid $green;
      }
    }

    [type=checkbox]:checked + span,
    [type=radio]:checked + span {
      background: $lime;
      box-shadow: $student-box-shadow;
    }

  }

  label > input[type=text],
  label > input[type=date] {
    margin: 0 0.5rem;
    width: 100%;
    border: 2px solid $light-gray;
    background: $light-gray;
    border-radius: 51px;
    min-height: 38px;
    padding: 0 1rem;
    max-width: 296px;
    @extend .student-text-p-italic;
  
    &:focus {
      border-color: $lime;
      outline: 0;
    }

    &::placeholder {
      font-style: italic;
    }
  }

  .student-survey-select {
    display: inline-block;
    width: 296px;
    margin: 0 0.5rem;
    font-family: $student-font-family-text;

    &.school-select {
      margin-top: 1rem;
      min-width: 400px;
      width: auto;
    }

    .react-select__control {
      background: $light-gray;
      border: 2px solid $light-gray;
      border-radius: 51px;
      padding-left: 0.5rem;

      &:focus-within,
      &:focus {
        border-color: $lime;
        outline: 0;
        box-shadow: none;
      } 

      .react-select__input {
        &:focus {
          outline: 0;
        }
      }

      .icon {
        margin-left: 0.25em;
        opacity: 0.5;
        transform: scale(0.8);
      }
    }

    .react-select__placeholder {
      font-style: italic;
    }

    .react-select__indicator-separator {
      background-color: $light-gray;
    }

    .react-select__indicator {
      svg {
        fill: $almost-black;
      }
    }

    .react-select__menu {
      border-radius: 24px;
      box-shadow: $student-box-shadow;
      overflow: hidden;
      
      .react-select__menu-list {
        padding: 0.5rem 0.25rem;

        .react-select__option {
          border-radius: 40px;

          span {
            @extend .student-text-p-small;
          }

          span:first-child {
            font-weight: bold;
          }
        }
      }
    }

    .react-select__menu .react-select__option--is-hovered,
    .react-select__menu .react-select__option--is-focused,
    .react-select__menu .react-select__option--is-selected {
      background-color: $light-lime;
      color: $almost-black;
    }

    .react-select__multi-value {
      background-color: white;
      border-radius: 32px;
      overflow: hidden;
      padding: 3px;
    }

    .react-select__multi-value__label {
      padding: 3px 8px 3px 10px;

      span:first-child {
        font-weight: bold;
      }
    }

    .react-select__multi-value__remove {
      border-radius: 50%;
      width: 23px;
      height: 23px;
      align-self: center;
      background: $lime;
      color: $almost-black;
    }

    &.error {
      .react-select__control {
        border-color: $error-red;
      }
    }
  }

  .student-survey-slider {
    max-width: 400px;

    .range-track {
      background-color: $lime;
    }

    .range-thumb {
      background-color: $dark-green;

      .range-thumb__value {
        top: 1.5em;
        text-align: center;
        @extend .student-text-p-small;
      }
    }

    .range-labels {
      @extend .student-text-p-small;
    }
  }
}