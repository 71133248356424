@import 'src/styles/variables';

.student-text-title {
  font-family: $student-font-family-headers;
  font-size: 2.5rem;
  font-weight: bolder;
  letter-spacing: 1px;
}

.student-text-h1 {
  font-family: $student-font-family-headers;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.student-text-h2 {
  font-family: $student-font-family-headers;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.student-text-h3 {
  font-family: $student-font-family-headers;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.student-text-h4 {
  font-family: $student-font-family-headers;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.student-text-p {
  font-family: $student-font-family-text;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0;
}

.student-text-p-italic {
  @extend .student-text-p;
  font-style: italic;
}

.student-text-p-small {
  font-family: $student-font-family-text;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0;
}

.student-text-p-small-italic {
  @extend .student-text-p-small;
  font-style: italic;
}

.student-text-p-large {
  font-family: $student-font-family-text;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0;
}

.student-text-p-large-bold {
  @extend .student-text-p-large;
  font-weight: bold;
}

.student-text-label {
  font-family: $student-font-family-headers;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.student-cta-link {
  font-family: $student-font-family-headers;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: underline;
  background: transparent;
  border: none;
  letter-spacing: 0;
}

.student-article-link {
  color: $dark-pink;
}

.inst-text-title {
  font-family: $inst-font-family;
  font-size: 2.5rem;
  font-weight: bolder;
  letter-spacing: 0;
}

.inst-text-h1 {
  font-family: $inst-font-family;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0;
}

.inst-text-h2 {
  font-family: $inst-font-family;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
}

.inst-text-h3 {
  font-family: $inst-font-family;
  font-size: 1.25rem;
  font-weight: bolder;
  letter-spacing: 0;
}

.inst-text-h4 {
  font-family: $inst-font-family;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0;
}

.inst-text-label {
  font-family: $inst-font-family;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
}

.inst-text-label-small {
  @extend .inst-text-label;
  font-size: 0.75rem;
  font-weight: bolder;
}

.inst-text-p {
  font-family: $inst-font-family;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
  letter-spacing: 0;
}

.inst-text-p-small {
  @extend .inst-text-p;
  font-size: 0.75rem;
}

.inst-text-p-large {
  @extend .inst-text-p;
  font-size: 1.125rem;
}

.inst-cta-link {
  font-family: $inst-font-family;
  font-size: 1.125rem;
  font-weight: normal;
  text-decoration: underline;
  background: transparent;
  border: none;
  letter-spacing: 0;
}