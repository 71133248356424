@import 'src/styles/variables';

.student-school-profile {
  padding-bottom: 3rem;

  .student-school-profile__top,
  .student-school-profile__content {
    padding: 1rem calc(2 * #{$student-main-padding-x}) 1rem $student-main-padding-x;

    .button {
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
  }
}