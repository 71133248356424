@import 'src/styles/variables';

.student-carousel {
  .react-multi-carousel-list{
    position: relative;
    left: -1rem;
    width: calc(100% + 2rem);
    
    .react-multiple-carousel__arrow {
      background: $lime;
      transform: scale(0.8);
  
      &::before {
        color: $dark-blue;
      }
  
      &.react-multiple-carousel__arrow--left {
        top: 52%;
        left: 0;
      }
  
      &.react-multiple-carousel__arrow--right {
        top: 52%;
        right: 0;
      }
    }
  }
}