@import 'src/styles/variables';

.tips-and-best-practices {
  .tips-body {
    padding: 0 2rem;
    
    a {
      text-decoration: none;
      color: black;
    }
    
    .shadow-card {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 474px auto;
      
      img {
        border-radius: 16px 0 0 16px;
        width: 100%;
      }
      
      .card-right {
        padding: 2rem;
        
        h1 {
          margin-bottom: 1rem;
        }
        
        span {
          color: $dark-gray;
        }
        
        p {
          margin-top: 1.5rem;
        }
      }
    }
  }
}