@import 'src/styles/variables';
@import 'src/styles/text';

.student-profile {
  padding-bottom: 6rem;

  .student-profile__content {
    padding: 0 $student-main-padding-x;
    margin-right: $student-main-padding-x;
  }

  .section {
    margin: 2rem 0 1rem;

    &:first-child {
      margin-top: 0;
    }

    .section__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  h1, h2 {
    margin: 0.5em 0;
  }

  .coming-soon {
    @extend .student-text-p-small-italic;
    color: $dark-pink;
  }

  .block {
    border: 1px solid gray;
    padding: 1em;
    margin-bottom: 1em;
  }

  .primary-button {
    @extend .student-text-label;
    background: white;
    border: 2px solid $green;
    color: $dark-green;
    padding: 0.6rem 1rem;
    border-radius: 100px;
    box-shadow: $student-box-shadow;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:disabled {
      border-color: $medium-green;
      box-shadow: none;
    }
  }

  .dotted-box {
    border: 2px dashed $light-green;
    background: white;
    border-radius: 16px;
    min-height: 190px;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    hr {
      width: 100%;
      border: none;
      height: 1rem;
      margin: 2rem 0;
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background: $light-green;
        position: absolute;
        top: 50%;
        left: 0;
      }

      &::after {
        @extend .student-text-h3;
        content: attr(data-content);
        color: black;
        background-color: white;
        position: absolute;
        top: 0;
        left: 50%;
        width: min-content;
        height: 1em;
        line-height: 1em;
        padding: 0 1em;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
      }
    }

    .dotted-box__top,
    .dotted-box__bottom {
      width: 100%;
    }

    .dotted-box__top {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      > * {
        margin: 1rem;
      }
    }
  }
}

.modal__body {
  padding: 1em;
}