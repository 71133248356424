@import 'src/styles/variables';
@import 'src/styles/text';

.inst-student-card {
  overflow: hidden;

  .inst-student-card__top {
    @extend .inst-text-h4;
    background: $inst-dark-green;
    color: white;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .inst-student-card__content {
    padding: 0.75rem;

    p {
      margin: 1rem 0;
    }

    .inst-text-p {
      color: $dark-gray;
    }

    .inst-student-card__buttons {
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: 0.5rem;
      }
    }
  }
}