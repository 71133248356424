@import 'src/styles/variables';
@import 'src/styles/text';

.student-messaging-popup {
  position: fixed;
  right: 4rem;
  width: 513px;
  bottom: 0;
  box-shadow: $student-box-shadow;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  z-index: 2000;

  @media screen and (min-width: 1440px) {
    right: calc(100vw - 1440px + 4rem);
  }

  .accordion {
    width: 513px;
    background: $almost-white;

    .count {
      background: $lime;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .accordion__button {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $light-peach;
    padding: 1rem 1.5rem;
    @extend .student-text-h2;
    box-shadow: $student-box-shadow;

    .count {
      height: 32px;
      width: 32px;
    }
  }

  .accordion__item--expanded .accordion__button img {
    transform: rotate(180deg);
  }

  .accordion__panel {
    padding: 1rem 1.5rem;
    height: 560px;

    .message {
      display: grid;
      grid-template-columns: 40px auto 40px;
      column-gap: 1rem;
      @extend .student-text-p-small;

      &:not(:last-child) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $peach;
      }

      span {
        display: block;
      }

      .gray {
        color: $dark-gray;
      }

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }

      .count {
        display: flex;
        width: 24px;
        height: 24px;
        @extend .student-text-h3;
      }
    }
  }
}