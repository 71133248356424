.back-link {
  color: inherit;
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;
    position: relative;
    top: 2px;
  }
}