@import 'src/styles/variables';
@import 'src/styles/text';

.student-todos-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-left: $student-main-padding-x;
  margin-right: calc(2 * #{$student-main-padding-x});

  .col {
    img.illustration {
      margin: 5rem auto;
    }
  }

  .col__header {
    background: $medium-peach;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 0.25rem 1rem;
    margin-bottom: 1rem;

    .student-add-button {
      @extend .student-text-p-small;
      text-decoration: none;
    }

    h1 {
      @extend .student-text-h2;
      text-transform: uppercase;
    }

  }

  .student-todo-accordion {
    margin-bottom: 3rem;

    .accordion__item,
    .accordion__item--expanded {
      margin-bottom: 1rem;
    }

    .accordion__item--expanded {
      margin-bottom: 0;
      img {
        transform: rotate(180deg);
      }
    }

    .accordion__button {
      @extend .student-text-h3;
      padding: 0.5rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $peach;

      .count {
        @extend .student-text-p-small-italic;
        color: $green;
        margin-left: 0.5rem;
      }
    }

    .accordion__panel {
      @extend .student-text-p;
      padding: 1rem 0;
    }
  }
}

.student-todo-panel {
  &.modal__bg {
    background-color: transparent;
  }

  .modal__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 567px;
    height: 100vh;
    box-shadow: $student-box-shadow;
    display: grid;
    grid-template-columns: 1fr;
    background: white;
  }

  .modal__body {
    border-radius: 0;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .modal__close--wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}