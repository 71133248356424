@import 'src/styles/variables';
@import 'src/styles/text';

.student-school-profile-financial-aid-card {
  padding: 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  > * {
    margin-bottom: 1rem;
  }

  a {
    @extend .student-cta-link;
    color: $dark-pink;
  }
}