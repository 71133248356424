@import 'src/styles/variables';
@import 'src/styles/text';

.profile-list-item {
  margin: 0 2rem;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 80% 20%;

  &:not(:last-child) {
    border-bottom: 2px solid $light-green;
  }
}

.student-delete-warning {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @extend .student-text-p-small;
  color: $error-red;

  button {
    @extend .student-text-label;
    color: black;
    border: 2px solid $error-red;
    border-radius: 100px;
    padding: 0.5rem 1.2rem;
    margin-top: 1rem;
    background: white;
  }
}