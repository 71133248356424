@import 'src/styles/variables';

.student-survey-nav-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;

  .prev,
  .next {
    background: $lime;
    border: none;
    box-shadow: $student-box-shadow;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 1.5rem;

    &[disabled] {
      opacity: 0.5;
    }
  }

  .prev img {
    transform: rotate(180deg);
  }
}