@import 'src/styles/variables';
@import 'src/styles/text';

.inst-candidate-search-card {
  overflow: hidden;

  &__top {
    @extend .inst-text-h4;
    background: $inst-light-green;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: transparent;
      border: none;
    }
  }

  &__content {
    padding: 0.75rem;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 2rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;

    > div:not(:first-child) {
      justify-self: flex-end;
    }
  }

  .filter-group {
    margin-bottom: 1rem;

    h3 {
      @extend .inst-text-label;
    }

    p {
      @extend .inst-text-p-small;
      color: $dark-gray;

      .label {
        color: $almost-black;
      }
    }

    .very-important,
    .less-important {
      @extend .inst-text-p-small;
      text-transform: capitalize;
      border: 1px solid $almost-black;
      border-radius: 30px;
      padding: 0.25rem 0.7rem;
    }

    .less-important {
      border-color: $inst-medium-green;
    }
  }

  .accordion__indicator {
    display: flex;
    align-items: center;
    @extend .student-text-p-small;

    img {
      margin-left: 0.5rem;
    }
  }

  .accordion__item {
    .close {
      display: none;
    }
  }

  .accordion__item--expanded {
    .open {
      display: none;
    }
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-count {
      @extend .inst-text-label;
      color: $inst-dark-pink;
    }
  }

  .accordion__panel {
    padding-top: 2rem;
  }
}