@import 'src/styles/text';

.student-assessment__content{
    padding: 0 2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

    h1 {
        margin-bottom: 0.5em;
    }

    .cards-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;
        margin-top: 2rem;
        .assessment-card {
            padding: 40px;
            .inst-text-p{
                margin-top: 16px;
            }
        }
    }
}