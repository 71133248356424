@import 'src/styles/variables';
@import 'src/styles/text';

.inst-favorites {
  margin: 0 $student-main-padding-x;
  padding-right: $student-main-padding-x;
  padding-bottom: 3rem;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .inst-favorites__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .sort {
      display: flex;
      align-items: center;
      @extend .inst-text-label;

      img {
        margin-left: 0.5rem;
        position: relative;
        top: 2px;
      }

      .coming-soon {
        @extend .inst-text-p;
        font-style: italic;
        color: $inst-dark-pink;
        text-transform: none;
        margin-right: 1rem;
      }
    }

    .inst-favorites__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .search {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      input[type=text] {
        border: 1px solid $inst-dark-peach;
        border-radius: 8px;
        background-position: 0.8rem 0.5rem;
        background-repeat: no-repeat;
        background-size: 1.5rem;
        @extend .student-text-p-italic;
        padding: 0.5rem 1rem 0.5rem 3rem;
        width: 416px;
        margin-right: 1rem;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .empty-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 600px;
    margin: 3rem auto;
    text-align: center;

    > * {
      margin: 1rem 0;
    }
  }

  .btn--outline {
    @extend .inst-text-label;
    text-decoration: none;
    padding: 0.75rem 2rem;
    min-width: 136px;
    text-align: center;
    border-radius: 8px;
    box-shadow: $student-box-shadow;
    border: 2px solid $inst-green;
    background: white;
    color: $inst-dark-green;
    border-color: $inst-dark-green;
  }
}