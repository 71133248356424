@import 'src/styles/variables';

[role=tooltip].student-popup-content {
  width: auto;
  box-shadow: $student-box-shadow;
  border: none;
  border-radius: 16px;

  .popup-arrow {
    display: none;
  }
}