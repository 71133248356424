@import 'src/styles/variables';
@import 'src/styles/text';
@import 'src/styles/mixins';

.inst-candidate-builder-form {
  width: 100%;
  min-height: calc(100vh - 100px);

  &__content {
    padding: 3rem 4rem;
  }

  .field,
  .flex {
    max-width: 416px;
    margin: 0.75rem 0;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
      @extend .inst-cta-link;
      color: $inst-dark-pink;
    }
  }

  fieldset {
    padding: 0;
    border: none;
  }

  label {
    @extend .inst-text-label;
  }

  label > input[type=text] {
    display: block;
    border: 1px solid $inst-medium-green;
    border-radius: 8px;
    @extend .student-text-p-italic;
    padding: 5px 8px;
    margin-top: 0.5rem;
    width: 416px;

    &:focus {
      outline-color: $inst-green;
    }
  }

  .accordion {
    
  }

  .accordion__item:not(:last-child) {
    border-bottom: 1px solid $inst-medium-green;
  }

  .accordion__button {
    padding: 1em 0;
    cursor: pointer;
    @extend .inst-text-h4;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &[aria-expanded="true"] {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    > fieldset:not(:first-child) {
      justify-self: flex-end;
    }
  }

  .accordion__panel {
    padding-bottom: 1em;

    .toggle-group {
      max-width: max-content;
      @extend .inst-text-label-small;
      border: 1px solid $inst-medium-green;
      border-radius: 40px;
      padding: 0.2rem;
      display: flex;
      align-items: center;

      &.disabled {
        border-color: $medium-gray;
      }

      label {
        padding: 0.2rem 0;
        input[type=radio] {
          position: absolute;
          top: 0;
          left: 0;
          appearance: none;
          width: 0.001px;
          height: 0.001px;
        }

        input[type=radio] + span {
          background: transparent;
          border-radius: 44px;
          padding: 0.25rem 0.8rem;
          color: $almost-black;
        }

        &:focus-within {
          [type=radio] + span {
            outline: 1;
          }
        }

        [type=radio]:checked + span {
          background: $inst-green;
          color: white;
        }

        [type=radio]:checked:disabled + span {
          background: $dark-gray;
        }
      }
    }

    fieldset {
      border: none;
      padding: 0;

      &.location > div {
        margin: 1rem 0;

        input {
          width: 100%;
        }
      }

      .radio {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        @extend .inst-text-label;
        margin: 0 1rem 0 0;

        span {
          margin-left: 0.5rem;
        }

        input[type=radio] {
          @include customRadioStyles($inst-green);
        }
      }

      .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0.3em 0 0.3em 0;
        @extend .inst-text-label;

        span {
          margin-left: 0.5rem;
        }

        input[type=checkbox],
        input[type=radio] {
          font-size: 1.2em;
          @include customCheckboxStyles($inst-green);
        }
      }
    }
  }

  .buttons {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    height: 128px;
    background-color: white;
    padding: 0 2rem;
    box-shadow: $student-box-shadow;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .inst-cta-link {
      color: $inst-dark-green;
      margin-left: 2rem;
    }

    .search-button {
      background-color: $inst-green;
      border: none;
      color: white;
      text-decoration: none;
      padding: 0.8rem 2rem;
      border-radius: 8px;
      box-shadow: $student-box-shadow;
    }
  }

  .inst-filters-multiselect {
    margin-top: 0.5rem;
    font-family: $student-font-family-text;

    .react-select__control--is-focused {
      box-shadow: 0 0 0 1px $inst-green;
    }

    .react-select__control {
      border: 1px solid $inst-medium-green;
      border-radius: 8px;

      &:focus-within,
      &:focus {
        outline: 0;
        border-color: $inst-green;
      } 

      .react-select__input {
        &:focus {
          outline: 0;
        }
      }

      .icon {
        margin-left: 0.25em;
        opacity: 0.5;
        transform: scale(0.8);
      }
    }

    .react-select__placeholder {
      font-style: italic;
    }

    .react-select__indicator-separator {
      background-color: $inst-medium-green;
    }

    .react-select__indicator {
      svg {
        fill: $inst-medium-green;
      }
    }

    .react-select__menu .react-select__option:hover,
    .react-select__menu .react-select__option--is-focused {
      background-color: $inst-medium-green;
    }

    .react-select__multi-value {
      background-color: $inst-medium-green;
      border-radius: 8px;
      overflow: hidden;
      padding: 3px;
    }

    .react-select__multi-value__label {
      padding: 3px 8px 3px 10px;
    }

    .react-select__multi-value__remove {
      width: 23px;
      height: 23px;
      align-self: center;
      color: inherit;

      &:hover {
        background: inherit;
      }
    }
  }

  .inst-filters-slider {
    max-width: 90%;
    margin-left: 5%;

    .range-track {
      background-color: $inst-dark-green;
      height: 4px;
    }

    .range-thumb {
      background-color: white;
      border: 2px solid $inst-dark-green;

      .range-thumb__value {
        top: 1.5em;
        text-align: center;
        @extend .student-text-p-small;
      }
    }

    .range-labels {
      @extend .student-text-p-small;
    }
  }
}

