@import 'src/styles/text';

.student-program-search-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  margin-bottom: 1rem;

  .student-program-search-card__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1rem;
  }

  .student-program-search-card__content {
    padding: 1rem 1rem 3rem 1rem;
    @extend .student-text-p;

    a {
      text-decoration: none;
      color: inherit;
    }

    .student-favorite__wrapper span {
      border: 1px solid $light-pink;
    }

    .student-program-search-card__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}