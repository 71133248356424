@import 'src/styles/variables';

.student-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em $student-main-padding-x;

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      margin: 0 2em;
    }

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 0.5em;
    }

    .link-item {
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;
      position: relative;
    }
  }
}