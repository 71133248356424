@import 'src/styles/variables';

.app-in-progress {
  text-decoration: none;
  color: inherit;

  .app-card {
    display: grid;
    grid-template-rows: auto auto;
    overflow: hidden;

    .app-card__top {
      background: $light-blue;
      padding: 1em;

      display: grid;
      grid-template-columns: 1fr 2fr 50px;
      align-items: center;

      .delete__wrapper {
        align-self: flex-start;
      }

      .delete {
        background: transparent;
        border: none;
      }
    }

    .app-card__bottom {
      padding: 0.5rem 2rem;

      ul {
        list-style: none;
        padding-left: 0;
        columns: 2;

        li {
          display: flex;
          align-items: center;
          height: 50px;

          img {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}