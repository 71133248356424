@import 'src/styles/variables';

.desktop-apps-carousel {
  color: $dark-blue;

  a {
    color: inherit;
    text-decoration: none;
  }

  .app-card {
    display: grid;
    grid-template-rows: auto auto;
    margin: 1rem;
    overflow: hidden;

    .app-card__top {
      background: $light-blue;
      padding: 1em;

      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }

    .app-card__bottom {
      padding: 1em;
      display: flex;
      align-items: center;

      img {
        margin-right: 1rem;
      }
    }
  }
}