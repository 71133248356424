@import 'src/styles/variables';

.student-dashboard {
  padding-bottom: 6rem;

  .student-dashboard__content {
    padding: 0 $student-main-padding-x;
    margin-right: $student-main-padding-x;
  }

  .section {
    margin: 2rem 0 1rem;

    h2 {
      margin-bottom: 1rem;
    }

    .section__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .dashboard-recs {
    
    h2 {
      margin-bottom: 0;
    }
    .student-cta-link {
      color: $dark-pink;
      display: flex;
      align-items: center;

      img {
        margin-right: 0.3em;
        position: relative;
        top: 2px;
      }
    }
  }

  .dashboard-apps {
    .student-cta-link {
      color: $dark-blue;
    }
  }

  .progress {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1em;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
    }

    .progress-instructions {
      max-width: 600px;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      img {
        max-width: 30%;
      }
    }

    .progress-button {
      background-color: $green;
      color: $almost-white;
      text-decoration: none;
      padding: 1rem 2rem;
      border-radius: 100px;
    }

    .progress-text {
      font-weight: bolder;
      padding: 2rem;
    }
  }

  .dashboard-search {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .dashboard-search__image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dashboard-search__content {
      padding: 2rem 0;

      p {
        margin: 0.5em 0 1.5em 0;
      }
    }
  }
}