@import 'src/styles/variables';

.inst-applications {
  padding: 2rem calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;
  width: 100%;

  .search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
}