@import 'src/styles/variables';

.student-notification-count {
  position: absolute;
  top: 0;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $lime;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $student-font-family-headers;
  font-size: 0.75rem;
  font-weight: bold;
}

.student-notification-panel {
  z-index: 3000;
  
  &.modal__bg {
    background-color: transparent;
  }

  .modal__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 567px;
    height: 100vh;
    box-shadow: $student-box-shadow;
    display: grid;
    grid-template-columns: 1fr;
    background: white;
  }

  .modal__body {
    border-radius: 0;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .modal__close--wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .student-notification-list {
    padding: 3rem 2rem;

    .notification-list-item {
      background: $almost-white;
      border: 2px solid $peach;
      border-radius: 8px;
      margin: 1rem 0;
      padding: 1rem;

      display: flex;
      align-items: center;

      img {
        margin-right: 1rem;
      }

      p:first-child {
        margin-bottom: 1rem;
      }
    }
  }
}