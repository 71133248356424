@import 'src/styles/variables';
@import 'src/styles/text';

.student-app-page {
  padding-bottom: 4rem;

  .student-app-page__content {
    padding: 0 $student-main-padding-x;
    margin-right: $student-main-padding-x;
  }

  .student-app-page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4rem;
  }
}

.student-app-accordion {
  margin-top: 2em;

  .accordion__item,
  .accordion__item--expanded {
    border-radius: 16px;
    margin-bottom: 1rem;
  }

  .accordion__item {
    border: 2px solid $light-blue;
  }

  .accordion__item--expanded {
    border: 2px solid $blue;
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;

    span {
      @extend .student-text-h2;
    }

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        margin-left: 2rem;
      }
    }
  }

  .accordion__item--expanded {
    .accordion__button img.carat {
      transform: rotate(180deg);
    }
  }

  .accordion__panel {
    padding: 0 1rem;
  }
}

.student-app-success-modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1, img, p {
    margin-bottom: 1rem;
  }
}