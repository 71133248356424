@import 'src/styles/variables';
@import 'src/styles/text';

.about-me {
  textarea {
    width: 100%;
    height: 180px;
    border-radius: 32px;
    padding: 2rem;
    border: 2px solid $medium-gray;
    resize: none;
    @extend .student-text-p;

    &::placeholder {
      font-style: italic;
    }
  }
}