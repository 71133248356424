@import '../../../styles/_text.scss';
@import 'src/styles/variables';

.article-page {
  img {
    width: 100%;
  }

  .text {
    padding: 1rem 5rem 3rem 5rem;

    p {
      @extend .student-text-p;
      margin-top: 1rem;

      > small {
        @extend .student-text-label;
      }
    }

    li {
      @extend .student-text-p;
      margin-top: 1rem;
    }

    h1 {
      @extend .student-text-h1;
      margin-top: 1rem;
    }

    h2 {
      @extend .student-text-h2;
      margin-top: 1rem;
    }

    h3 {
      @extend .student-text-h3;
      margin-top: 1rem;
    }

    a {
      @extend .student-article-link;
    }
  }
}
