@import 'src/styles/variables';
@import 'src/styles/_text';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    color: black;
    background-color: $dark-peach;
    width: 15%;
    border: none;
    border-radius: 100px;
    padding: 1rem 0 1rem 0;
    z-index: 1;
  }
}

.info-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .info-card {
    width: 48%;
    height: 200px;
    background-color: $medium-peach;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $dark-pink;
    padding: 2rem;

    .card-header {
      display: flex;
      position: relative;

      span {
        margin-right: 1rem;
      }

      img {
        cursor: pointer;
        height: 20px;
      }

      .tooltipContainer {
        margin-left: 2rem;
        position: absolute;
        top: 2rem;
        left: -2.25rem;

        .tooltip {
          color: black;
          height: auto;
          width: 20rem;
          z-index: 1;
          background: white;
          padding: 1.5rem;
          border-radius: 8px;
          font-family: $student-font-family-text;
          box-shadow: 0 0 15px 0 rgba(44, 26, 11, 15%);
        }
      }
    }

    h1 {
      margin-top: 2rem;
    }
  }
}

.graph-container {
  height: 600px;
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 20px 0;

  .legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      span {
        display: inline-block;
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
        background-color: lightgray;
      }

      #red {
        border: 4px solid red;
      }

      #green {
        border: 4px solid #79B594;
      }

      #black {
        border: 4px solid black;
      }
    }
  }
}
