@import 'src/styles/variables';
@import 'src/styles/text';

.inst-search-input {
  background-position: 0.5rem 0.5rem;
  background-repeat: no-repeat;
  background-size: 1.5em;

  border: 1px solid $inst-dark-peach;
  border-radius: 8px;
  @extend .student-text-p;
  color: $dark-gray;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  min-width: 416px;
  margin-right: 1rem;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    outline-color: $dark-peach;
  }
}