.shortcut-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  column-gap: 1rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  .shortcut-card {
    height: 100%;
    padding: 1rem;

    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1rem;
    align-items: center;

    .shortcut-card__image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}