@import 'src/styles/text';

.inst-most-compatible {
  img {
    margin-left: 0.3rem;
    position: relative;
    top: 1px;
  }

  button {
    background: transparent;
    border: none;
    padding: 0 0.5rem;
    white-space: nowrap;
  }
}

.inst-most-compatible-popup {
  max-width: 240px;
  padding: 1rem;
  @extend .inst-text-p-small;
}