@import 'src/styles/variables';
@import 'src/styles/text';

.inst-favorite__wrapper {
  display: flex;
  align-items: center;
  @extend .student-text-p-small;
  font-style: italic;
  color: $inst-dark-pink;
  white-space: nowrap;

  button {
    background: transparent;
    border: none;
    margin-left: 0.5rem;
  }
}