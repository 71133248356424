$error-red: #CF1414;
$approved-green: #389429;

// student
$light-yellow: #FFE5A0;
$dark-yellow: #79370D;
$lime: #CEE741;
$dark-lime: #8FA415;
$light-lime: #EBF5B3;
$green: #0C642C;
$dark-green: #0A260D;
$medium-green: #B2C2B8;
$light-green: #E0ECC7;
$dark-peach: #DB854F;
$medium-dark-peach: #EFAB83;
$peach: #FBD0B5;
$medium-peach: #FFECE0;
$light-peach: #FFF5EF;
$almost-white: #FFFDFC;
$almost-black: #313133;
$dark-gray: #69696B;
$medium-gray: #B4B4B7;
$light-gray: #EFEFF1;
$pink: #CE025F;
$dark-pink: #6C162B;
$medium-pink: #CDA4B7;
$light-pink: #F8CCD4;
$blue: #1718AF;
$dark-blue: #010256;
$medium-blue: #B0B1C8;
$light-blue: #D1D9EF;

$inst-dark-lime: #839615;
$inst-lime: $lime;
$inst-light-lime: $light-lime;
$inst-green: $green;
$inst-dark-green: #0B2F0F;
$inst-light-green: #E2EAD2;
$inst-medium-green: #B7CABE;
$inst-light-peach: #F5ECE5;
$inst-medium-peach: #FFD8C0;
$inst-dark-peach: #EFAB83;
$inst-pink: #C00B5D;
$inst-dark-pink: #5B0F22;
$inst-light-pink: $light-pink;

$student-box-shadow: 0 0 15px 0 rgba(68, 38, 17, 0.15);

$student-font-family-headers: degular-display, sans-serif;
$student-font-family-text: lato, sans-serif;

$student-main-padding-x: 2rem;

$inst-font-family: lato, sans-serif;