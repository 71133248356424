@import 'src/styles/variables';
@import 'src/styles/text';

.student-contact-us-button {
  background: $green;
  color: white;
  text-decoration: none;
  @extend .student-text-label;

  position: fixed;
  bottom: 3rem;
  right: 3rem;

  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 100px;
  box-shadow: $student-box-shadow;

  img {
    margin-right: 0.5rem;
  }
}