@import '../../../styles/variables.scss';
@import '../../../styles/_text.scss';

.list-purchase-info {
  padding: 5rem 2rem;

  .list-purchase-info-header {
    display: flex;
    align-items: flex-start;
    padding: 0 3rem 2rem 3rem;
    border-bottom: 2px solid $inst-light-green;

    .list-purchase-info-header-explainer {
      width: 55%;
      margin-left: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      h2 {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  .list-purchase-info-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 3rem 0 3rem;

    p {
      margin-top: 1rem;

      span {
        padding-right: 0.5rem;
      }
    }

    button {
      cursor: pointer;
      background: $green;
      border: none;
      color: white;
      text-transform: uppercase;
      padding: 1rem;
      border-radius: 8px;
      margin-top: 2rem;
      @extend .inst-text-p-small;
    }
  }
}

.saved-searches, .order-history {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 1rem;
  }

  .saved-searches-cards, .order-history-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .shadow-card {
      width: 21rem;

      .saved-search, .order-card {
        .saved-search-top {
          border-radius: 16px 16px 0 0;
          padding: 0.6rem 1rem;
          height: 3rem;
          background: $peach;
          display: flex;
          justify-content: space-between;

          .icons {
            display: flex;
            justify-content: space-between;

            img {
              cursor: pointer;
            }
          }
        }

        .order-card-top {
          border-radius: 16px 16px 0 0;
          padding: 0.6rem 1rem;
          height: 3rem;
          background: $medium-green;
          display: flex;
          justify-content: space-between;
        }

        .saved-search-bottom, .order-card-bottom {
          padding: 1.5rem 1.5rem;
          display: flex;
          flex-direction: column;

          .description {
            display: flex;
            flex-direction: column;

            > .inst-text-label {
              color: grey;
            }
          }

          .links {
            margin-top: 1rem;

            .order-button {
              background: white;
              border: 2px solid $green;
              color: $green;
              border-radius: 8px;
              text-transform: uppercase;
              padding: .75rem 1.5rem;
              margin-right: 1.5rem;
            }

            .details-button {
              background: none;
              border: none;
              text-decoration: underline;
              color: $dark-pink;
            }
          }
        }

        .order-card-bottom {
          padding: 2rem 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 17.5rem;

          .info {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            > .inst-text-p {
              color: grey;
            }
          }

          .recurring-purchase {
            display: flex;
            width: 70%;
            padding: 0.5rem 0.5rem;
            align-items: center;
            background: $lime;
            border-radius: 16px;
            margin-bottom: 1rem;

            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}