@import 'src/styles/variables';

.roi-page {
  h2, p {
    margin-bottom: 20px;
  }

  margin: 2rem;

  .accordion__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    margin: 1rem 0;
    background-color: $medium-peach;
    border-radius: 8px;
  }

  .accordion__item--expanded {
    .accordion__button img {
      transform: rotate(180deg);
    }
  }
}
