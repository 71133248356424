@import 'src/styles/variables';
@import 'src/styles/text';

.inst-app-status {
  @extend .inst-text-label-small;
  width: max-content;
  border-radius: 58px;
  padding: 0.3rem 1rem;

  &.submitted {
    background-color: $inst-light-pink;
  }

  &.reviewed {
    background-color: $inst-medium-green;
  }

  &.in-progress {
    background-color: $inst-medium-peach;
  }
}