@import 'src/styles/variables';
@import 'src/styles/text';

.inst-funnel-chart {
  position: relative;
  margin-bottom: 2rem;

  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
    text-align: center;
    @extend .inst-text-h4;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    button {
      background: transparent;
      border: none;
    }
  }

  &__chart {
    @extend .inst-text-p-small;
  }
}