@import 'src/styles/variables';
@import 'src/styles/_text';

.student-insights-main {
  padding: 2rem;
  
  .dark-pink {
    color: $inst-dark-pink;
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    
    .filters {
      display: flex;
      align-items: center;
      color: $inst-dark-pink;
      
      button {
        color: $inst-dark-pink;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        margin-right: 1rem;
        cursor: pointer;
        
        &.inst-text-p {
          text-decoration: underline;
        }
        
        img {
          height: 20px;
          margin-right: 0.5rem;
          
          &.carat {
            height: 10px;
            margin-left: 0.5rem;
            
            &.reverse {
              transform: rotate(180deg);
            }
          }
        }
      }
      
      .student-filter-wrapper {
        position: relative;
        
        .students-dropdown {
          position: absolute;
          z-index: 2;
          background: white;
          right: 1.5rem;
          top: 2rem;
          
          .shadow-card {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            width: 17rem;
            
            button {
              color: black;
              text-decoration: none;
              margin-top: .5rem;
              
              &.bold {
                font-weight: 800;
              }
            }
          }
        }
      }
      
      .time-filter-wrapper {
        position: relative;
        
        .time-dropdown {
          position: absolute;
          z-index: 2;
          background: white;
          right: 1rem;
          top: 2rem;
          
          .shadow-card {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            width: 12rem;
            
            button {
              color: black;
              text-decoration: none;
              margin-top: .5rem;
              
              &.bold {
                font-weight: 800;
              }
            }
          }
        }
      }
    }
  }
  
  .soft-skills {
    margin: 1rem 0;
    
    .shadow-card {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .average-scores {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .shadow-card {
      width: 33rem;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      margin-bottom: 1rem;
      
      .progress-circles {
        display: flex;
        justify-content: space-between;
        
        &.center {
          justify-content: center;
        }
        
        &.margin-top {
          margin-top: 2rem;
        }
        
        .progress-circle {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  
  .diversity {
    .shadow-card {
      padding: 2rem;
      
      .geographic, .ethnicity {
        display: flex;
        justify-content: flex-start;
        
        img {
          height: 330px;
          width: 550px;
          margin-right: 2.5rem;
        }
        
        .geographic-bars, .ethnicity-bars {
          .geographic-bar, .ethnicity-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            
            span {
              width: 10rem;
              
              &.wider {
                width: 13rem;
              }
            }
            
            .geographic-bar-stats, .ethnicity-bar-stats {
              display: flex;
              justify-content: flex-start;
            }
            
            .bar-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 12rem;
              margin-right: 1rem;
            }
          }
          
          .disclaimer {
            font-style: italic;
            width: 80%;
            margin-top: 1rem;
            display: block;
          }
        }
      }
    }
    
    .bottom-shadow-cards {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      justify-content: space-between;
      
      .shadow-card {
        width: 33rem;
        margin-bottom: 1rem;
        
        .legend__label {
          @extend .inst-text-label;
        }
        
        .chart-wrapper {
          height: 80%;
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  
  .inst-search-filter-container {
    width: 3.5rem;
    
    button {
      @extend .inst-text-p;
    }
  }
}