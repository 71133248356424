@import 'src/styles/variables';
@import 'src/styles/text';

.inst-btn {
  @extend .inst-text-label;
  text-decoration: none;
  padding: 0.75rem 2rem;
  min-width: 136px;
  text-align: center;
  border-radius: 8px;
  box-shadow: $student-box-shadow;
  border: 2px solid $inst-green;

  &--primary {
    background: $inst-green;
    color: white;
  }

  &--outline {
    background: white;
    color: $inst-dark-green;
    border-color: $inst-dark-green;
  }
}