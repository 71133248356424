@import 'src/styles/text';
@import 'src/styles/variables';

.inst-dashboard {

  &__content {
    padding: 0 calc(2 * #{$student-main-padding-x}) 0 $student-main-padding-x;

    section {
      margin-bottom: 40px;
    }

    .welcome {
      padding: 48px 80px;
      a {
        display: inline-block;
        margin-top: 1rem;
      }
    }

    .program-performance-section {
      h2 {
        margin-bottom: 0.2rem;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        column-gap: 1rem;
        margin-bottom: 1rem;
      }

      .stats-grid {
        display: grid;
        grid-template-columns: 2fr repeat(4, 1fr);
        column-gap: 1rem;
      }

      .performance-cards {
        padding: 1.5rem;
        display: grid;
        grid-template-rows: auto auto;
        text-align: center;

        > .inst-text-label {
          margin-bottom: 1rem;
        }
        
        .stats-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          border-radius: 1rem;
          background-color: $inst-light-green;

          .stats {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;

            > .inst-text-p-small {
              margin: 0.5rem 0;
            }
          }

          img {
            width: 40px;
            height: 40px;
            color: $inst-light-green;
          }
        }
      }
    }
    
    .search-redirect {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 145px 222px 64px;
      
      .blurb{
        position: relative;
        margin-bottom: 32px;

        img {
          position: absolute;
          left: -140px;
          top: -87px;
        }
      }
    }

    .flex-row {
      display: flex !important;
      flex-direction: row !important;
    }
  }
}