@import 'src/styles/variables';
@import 'src/styles/text';

.student-onboarding-survey {

  .btn-gray {
    min-width: 175px;
    text-decoration: none;
    @extend .student-text-h3;
    background: $light-gray;
    color: $almost-black;
    margin-right: 1rem;
    margin-top: 2rem;
    padding: 0.75rem 1.75rem;
    border: 2px solid $light-gray;
    border-radius: 100px;

    &:hover {
      background: $lime;
      box-shadow: $student-box-shadow;
    }
  }

  .carousel-container {
    width: 750px;
    max-width: 100%;
    display: block;

    .carousel-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -0.5rem;

      .student-cta-link {
        color: $dark-pink;
      }
    }
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;

    > img {
      margin-bottom: 1rem;
    }

    > p {
      max-width: 65%;
      margin: 0.5rem auto;
      text-align: center;

      &.student-text-h2 {
        max-width: 75%;
      }
    }

    .btn {
      text-decoration: none;
      @extend .student-text-label;
      background: $green;
      color: white;
      margin: 2rem 0.5rem 1rem;
      padding: 0.5rem 1.5rem;
      border: 2px solid $green;
      border-radius: 100px;
      box-shadow: $student-box-shadow;
    }

    .btn-outline {
      text-decoration: none;
      @extend .student-text-label;
      background: white;
      color: $dark-green;
      margin: 2rem 0.5rem 1rem;
      padding: 0.5rem 1.5rem;
      border: none;
      border-radius: 100px;
      border: 2px solid $green;
      box-shadow: $student-box-shadow;
    }

    .flex {
      margin: 3rem 0 2rem;
    }

    .student-cta-link {
      color: $dark-green;
    }
  }

  .coming-soon {
    @extend .student-text-p-small-italic;
    color: $dark-pink;
  }

  .flex {
    display: flex;
    align-items: center;
  }
  
}