@import 'src/styles/variables';
@import 'src/styles/text';

.student-favorite__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span,button {
    height: 34px;
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    white-space: nowrap;
  }

  span {
    background: $almost-white;
    @extend .student-text-p-small-italic;
  }

  button {
    display: flex;
    align-items: center;
    background: $light-peach;
    border: none;
    @extend .student-text-p-small;

    img {
      margin-right: 0.5rem;
    }
  }
}