@import 'src/styles/variables';
@import 'src/styles/text';

body {
  background-color: $almost-white;
}

.student-layout-container {
  display: flex;
  min-height: 100vh;
  max-width: 1440px;

  .student-layout-container__sidebar {
    background: $light-peach;
    max-width: 285px;
    padding: 1.5em;
    box-shadow: $student-box-shadow;

    .icon {
      margin: 0 1em;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 2em;

      li {
        margin: 0.2em 0;
      }
    }

    hr {
      background: $peach;
      height: 1px;
      border: none;
      margin: 1em 0;
    }

    ul a,
    .logout {
      width: 100%;
      border: 0;
      background: transparent;
      cursor: pointer;

      display: flex;
      align-items: center;
      border-radius: 58px;
      padding: 0.5em 0.8em;
      color: inherit;
      text-decoration: none;
      font-weight: normal;

      &:hover,
      &.active {
        background-color: $lime;
      }


      img {
        height: 1em;
        margin-right: 0.5em;
      }
    }

    .contact-us {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 1.5rem;
      background: $almost-white;
      margin-top: 1rem;

      p {
        align-self: flex-start;
      }

      > * {
        margin: 0.3rem 0;
      }

      a {
        background: white;
        border: 2px solid $green;
        text-decoration: none;
        padding: 0.5rem 1.25rem;
        border-radius: 100px;
        color: inherit;
        box-shadow: $student-box-shadow;
        @extend .student-text-label;
      }
    }
  }

  .student-layout-container__main {
    flex: 1;
    overflow: auto;
  }
}