@import 'src/styles/variables';

.student-todo-block {
  width: 100%;
  text-align: left;
  background: transparent;
  border: 2px solid $peach;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem 1rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: block;

    &:first-child {
      margin-bottom: 0.5rem;
    }
  }

  &.done {
    border-color: $medium-gray;
    color: $dark-gray;
  }
}

button.student-todo-block {
  &:not(:disabled):hover {
    border-color: $dark-peach;
  }
}