.range-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1em 0;
  padding: 1em 0;

  .range-track {
    height: 8px;
    width: 100%;
    background-color: #CCC;
    border-radius: 4px;
    align-self: center;
  }

  .range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #AAA;
    display: flex;
    flex-direction: column;
    align-items: center;

    .range-thumb__value {
      position: relative;
      top: -1.2em;
    }
  }

  .range-labels {
    margin-top: 0.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}