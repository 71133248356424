@import 'src/styles/variables';
@import 'src/styles/text';
@import 'src/styles/mixins';

.student-app-form {
  padding-bottom: 2rem;

  p {
    @extend .student-text-p;

    .student-cta-link {
      color: $blue;
    }

    .coming-soon {
      @extend .student-text-p-small-italic;
      color: $dark-pink;
    }
  }

  p + p {
    margin-top: 1rem;
  }

  .field,
  .dotted-box,
  .flex {
    max-width: 450px;
  }

  label {
    @extend .student-text-label;
  }

  legend {
    @extend .student-text-h2;
    margin-bottom: 2rem;

    &.no-margin {
      margin: 0;
    }
  }

  fieldset {
    margin: 2rem 0;
    border: 0;
    padding: 0;

    &.no-margin {
      margin: 0;
    }

    fieldset legend {
      @extend .student-text-label;
      margin-bottom: 0;
    }
  }

  hr {
    border: none;
    background: $light-blue;
    height: 2px;
    margin: 2rem 0;
  }

  .field + .field,
  .flex,
  .flex + .field {
    margin-top: 1.5rem;
  }

  .flex > .field + .field {
    margin: 0;
  }


  .field {
    .error-msg {
      @extend .student-text-p-small;
      color: $error-red;
    }

    &.error {
      input[type=text],
      input[type=date] {
        border-color: $error-red;
      }
    }

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    > * {
      width: 100%;
    }

    .radio {
      margin-right: 1rem;
    }
  }

  .radio {
    display: flex;
    align-items: center;
    @extend .student-text-p;
    text-transform: none;

    input[type=radio] {
      margin-right: 0.5rem;
      @include customCheckboxStyles($blue);
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    @extend .student-text-p;
    text-transform: none;

    input[type=checkbox] {
      margin-right: 0.5rem;
      @include customCheckboxStyles($blue);
    }

    &.rec {
      margin-bottom: 1rem;
      span:first-child {
        font-weight: bold;
      }
    }
  }

  label > input[type=text],
  label > input[type=date],
  textarea {
    margin: 0.5rem 0;
    width: 100%;
    border: 2px solid $light-blue;
    border-radius: 51px;
    font-family: $student-font-family-text;
    min-height: 38px;
    padding: 0 1rem;
  
    &:focus {
      border-color: $blue;
      outline: 0;
    }

    &::placeholder {
      font-style: italic;
    }
  }

  textarea {
    height: 130px;
    resize: none;
    border-radius: 32px;
    padding: 1rem;
  }

  .student-app-select {
    @include customStudentMultiSelectStyles($blue, $light-blue);
  }

  .dotted-box {
    margin: 1.5rem 0;
    border: 2px dashed $light-blue;
    background: white;
    border-radius: 16px;
    min-height: 190px;
    padding: 2rem;

    p:first-child {
      @extend .student-text-label;
    }

    p + p {
      margin: 1rem 0;
    }

    button {
      @extend .student-text-label;
      background: $blue;
      color: white;
      border: none;
      border-radius: 100px;
      padding: 0.5rem 1.5rem;
      min-width: 146px;
      box-shadow: $student-box-shadow;
  
      &[disabled] {
        background: $medium-blue;
      }
    }
  }

  button[type=submit],
  .submit {
    @extend .student-text-label;
    background: $blue;
    color: white;
    border: none;
    border-radius: 100px;
    padding: 0.5rem 1.5rem;
    min-width: 146px;
    box-shadow: $student-box-shadow;

    &[disabled] {
      background: $medium-blue;
    }
  }

  button.add-one {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background: transparent;
    border: none;

    img {
      border-radius: 50%;
      border: 1px solid $blue;
      margin-right: 0.5rem;
    }

    &[disabled] {
      opacity: 1;
      color: inherit;
    }

    .coming-soon {
      @extend .student-text-p-italic;
      text-decoration: none;
      margin-left: 0.5rem;
    }
  }
}