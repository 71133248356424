@import 'src/styles/text';

.student-add-app-form {
  width: 416px;
  margin: 2rem 0 0;

  button[type=submit] {
    margin-top: 3rem;
  }

  .student-programs-select {

    .react-select__menu {
      box-shadow: none;
      border: 1px solid $light-blue;
    }

    .react-select__menu-list {
      padding: 0.5rem 0.25rem;
      max-height: 150px;
    }

    .react-select__multi-value__label {
      padding: 3px 8px 3px 10px;

      span:first-child {
        font-weight: bold;
      }
    }

    .react-select__option {
      border-radius: 40px;

      span {
        @extend .student-text-p-small;
      }

      span:first-child {
        font-weight: bold;
      }
    }
  }
}