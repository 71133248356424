@import 'src/styles/variables';
@import 'src/styles/text';

.inst-info-tag {
  display: flex;
  align-items: center;
  @extend .inst-text-label-small;
  background-color: $light-green;
  color: $dark-green;
  padding: 0.4rem 1rem;
  border-radius: 58px;
  white-space: nowrap;
}