@import 'src/styles/variables';

.tips-and-best-practices-trends {

  .back-link {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  
  .banner-image {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    object-position: 0 100%;
  }
  
  .article-content {
    padding: 2rem;
    
    h1 {
      margin-bottom: 1rem;
    }
    
    span {
      color: gray;
    }
    
    p {
      margin-top: 1rem;
    }
    
    .info-banner {
      background-color: $inst-light-green;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 9rem;
      margin-top: 1rem;
      
      span {
        color: black;
      }
    }
    
    .shadow-card {
      padding: 2rem;
    }
    
    .student-averages {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      span {
        color: black;
      }
      
      .shadow-card {
        width: 48%;
      }
      
      .progress-circles {
        display: flex;
        justify-content: center;
        
        .progress-circle {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 1rem;
          
          h1 {
            margin: 0;
          }
        }
      }
    }
    
    .academic-interests {
      margin-top: 1rem;
      
      .shadow-card {
        padding: 2rem 9rem 2rem 2rem;
        
        span {
          color: black;
        }
      }
      
      .academic-interests-header {
        margin-bottom: 2rem;
      }
      
      .academic-interest {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        
        .academic-interest-stats {
          display: flex;
        }
        
        .bar-wrapper {
          display: flex;
          align-items: center;
          width: 20rem;
          margin-right: 1rem;
        }
      }
    }
  }
}