@import 'src/styles/text';

.student-survey-dashboard-link {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  .dashboard {
    @extend .student-cta-link;
    color: inherit;

    display: flex;
    align-items: center;

    img { 
      margin-right: 0.5rem;
    }
  }
}

.student-leave-survey-warning {
  padding: 1rem;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    @extend .student-text-p-small;
    color: $error-red;
    text-align: center;
  }

  a {
    @extend .student-text-label;
    border: 2px solid $error-red;
    border-radius: 100px;
    padding: 0.75rem 1.25rem;
    text-decoration: none;
    color: inherit;
    margin-top: 1rem;
  }
}