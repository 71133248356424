@import 'src/styles/variables';

.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 3000;

  .modal__wrapper {
    display: grid;
    grid-template-columns: auto 40px;
  }

  .modal__close {
    min-width: 32px;
    margin-left: 10px;

    width: 32px;
    height: 32px;
    background: $lime;
    border-radius: 50%;
    color: black;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .modal__body {
    min-width: 500px;
    max-width: 50vw;
    max-height: 70vh;
    background: white;
    overflow-y: auto;
    padding: 0;
    border-radius: 32px;
  }
}