@import 'src/styles/variables';
@import 'src/styles/text';

.login {
  min-height: 100vh;
  width: 100vw;
  background: $light-peach;

  .login__header {
    width: 100vw;
    height: 104px;
    background: $light-peach;
    box-shadow: $student-box-shadow;
    display: flex;
    align-items: center;
    padding: 1.5rem;
  }

  .login__content {
    max-width: 1440px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6rem;
    padding: 4rem;
    align-items: center;
  }

  .form__wrapper {
    background: white;
    width: 528px;
    padding: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      margin-bottom: 1rem;
    }

    > p {
      margin-bottom: 1rem;
    }

    .error-msg {
      @extend .student-text-p-small;
      color: $error-red;
    }

    .success-msg {
      @extend .student-text-p-small;
      color: $green;
    }

    form {
      margin: 2rem 0 0;
      text-align: left;
      width: 100%;

      a {
        @extend .student-cta-link;
        color: inherit;
      }

      p {
        text-align: right;
      }

      label, legend {
        @extend .student-text-label;
      }

      .field + .field {
        margin-top: 1.5rem;
      }

      .field {
        &.error {
          input[type=text],
          input[type=password],
          input[type=email] {
            border-color: $error-red;
          }
        }
  
        &.center {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2rem;
        }
      }

      label > input[type=text],
      label > input[type=password],
      label > input[type=email] {
        margin: 0.5rem 0;
        width: 100%;
        border: 2px solid $light-green;
        border-radius: 51px;
        min-height: 38px;
        padding: 0 1rem;
        @extend .student-text-p;
        font-weight: normal;
      
        &:focus {
          border-color: $green;
          outline: 0;
        }
  
        &::placeholder {
          font-style: italic;
        }
      }

      button[type=submit] {
        @extend .student-text-label;
        background: $green;
        color: white;
        border: none;
        border-radius: 100px;
        padding: 0.5rem 1.5rem;
        min-width: 146px;
        box-shadow: $student-box-shadow;
  
        &[disabled] {
          opacity: 0.5;
        }
      }
    }
  }
}