@import 'src/styles/variables';

.profile-gre-score {
  padding: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 15% auto 15%;
  column-gap: 2rem;

  .profile-gre-score__title {
    border-right: 2px solid $light-green;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-gre-score__scores {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    .score {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .profile-gre-score__buttons {
    justify-self: flex-end;

    button {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 50%;
      margin-left: 1rem;
    }
  }
}