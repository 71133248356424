.student-survey-steps {
  .step-label {
    text-align: right;
    padding-right: 1rem;
    min-width: max-content;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}