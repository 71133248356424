@import 'src/styles/variables';
@import 'src/styles/text';

.student-todo-form {
  padding: 1.5rem 1rem 3rem;

  .student-todo-form__buttons {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $peach;
    padding-bottom: 1rem;
    height: 40px;

    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      @extend .student-text-p-small;

      img {
        margin-right: 0.5rem;
      }
    }
  }

  form {
    padding-top: 1rem;
    max-width: 416px;

    label, legend {
      @extend .student-text-label;
    }

    .field + .field {
      margin-top: 1rem;
    }

    .field {
      .error-msg {
        @extend .student-text-p-small;
        color: $error-red;
      }

      &.error {
        input[type=text],
        input[type=date] {
          border-color: $error-red;
        }
      }
    }

    label > input[type=text],
    label > input[type=date],
    textarea {
      margin: 0.5rem 0;
      width: 100%;
      border: 2px solid $peach;
      background: $almost-white;
      border-radius: 51px;
      font-family: $student-font-family-text;
      min-height: 38px;
      padding: 0 1rem;

      &[disabled] {
        background: $light-gray;
        border-color: $medium-gray;
      }
    
      &:focus {
        border-color: $dark-peach;
        outline: 0;
      }

      &::placeholder {
        font-style: italic;
      }
    }

    label > input[name="name"] {
      @extend .student-text-h2;
    }

    textarea {
      height: 130px;
      resize: none;
      border-radius: 32px;
      padding: 1rem;
    }

    button[type=submit] {
      @extend .student-text-label;
      background: $peach;
      border: none;
      border-radius: 100px;
      padding: 0.5rem 1.5rem;
      min-width: 146px;
      box-shadow: $student-box-shadow;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}