@import 'src/styles/variables';

.inst-student-basic-info {
  padding: 2rem;
  display: grid;
  grid-template-columns: 150px auto 400px;

  &__left {
    align-self: center;
    
    > * {
      margin: 0;
    }

    .primary-button {
      margin-top: 0.8rem;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    button {
      background: transparent;
      padding: 0;
      border-radius: 50%;
      border: none;
      cursor: pointer;
    }

    .bubbles {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        background-color: $medium-peach;
        border-radius: 40px;
        padding: 0.5rem 1.2rem;
        margin-top: 0.5rem;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  
    > * {
      margin-left: 1rem;
    }
  }
}