@import '../../../styles/variables.scss';
@import '../../../styles/_text.scss';
@import 'src/styles/mixins';

.list-purchase-search {
  margin-bottom: 2rem;
  
  input[type=checkbox] {
    cursor: pointer;
    position: relative;
    top: 0.35rem;
    @include customCheckboxStyles($green);
    
    &:checked {
      &:after {
        left: 3px;
      }
    }
  }

  button {
    display: flex;
    background: none;
    border: none;
    text-decoration: underline;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      position: relative;
      top: 0.15rem;
    }
  }

  input[type="text"] {
    margin-top: 0.5rem;
    height: 2.4rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid $inst-light-green;

    &:focus {
      outline-color: $inst-green;
    }
  }

  .section {
    .section-header {
      width: 100%;
      background: $inst-light-green;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      border-radius: 8px;

      span.left {
        font-weight: 700;
        margin-right: 1rem;
      }

      span.right {
        margin-left: 1rem;
      }
    }
    
    .accordion__item {
      border-bottom: 2px solid $medium-green;
    }

    .accordion__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1.5rem;
      margin: 1rem 0;
      cursor: pointer;
    }

    .accordion__item--expanded {
      padding-bottom: 1rem;
      border-bottom: 2px solid $medium-green;
      
      .accordion__button img {
        transform: rotate(180deg);
      }
    }

    .accordion-item-panel {
      margin-left: 2rem;

      .available-soon {
        @extend .inst-text-p;
        color: $dark-pink;
        font-style: italic;
      }

      .underline {
        text-decoration: underline;
      }

      .horizontal-row {
        display: flex;
        align-items: center;

        .search-form-text {
          margin-left: 2rem;
        }
      }

      .search-form-text {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;

        input {
          margin-top: 0.5rem;
          height: 3.4rem;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          border: 1px solid $inst-light-green;
        }
      }

      .search-form-select {
        padding: 1rem 0;

        label {
          margin-bottom: 0.5rem;
        }

        .search-form-select-dropdown {
          margin-top: 0.5rem;
          width: 30rem;
          font-family: $student-font-family-text;

          .react-select__control--is-focused {
            box-shadow: 0 0 0 1px $inst-green;
          }

          .react-select__control {
            border: 1px solid $inst-medium-green;
            border-radius: 8px;

            &:focus-within,
            &:focus {
              outline: 0;
              border-color: $inst-green;
            } 
            
            &.react-select__control--is-disabled {
              border: 2px solid $medium-gray;
              
              svg {
                fill: $dark-gray;
              }
            }

            .react-select__input {
              &:focus {
                outline: 0;
              }
            }

            .icon {
              margin-left: 0.25em;
              opacity: 0.5;
              transform: scale(0.8);
            }
          }

          .react-select__placeholder {
            font-style: italic;
          }

          .react-select__indicator-separator {
            background-color: $inst-medium-green;
          }

          .react-select__indicator {
            svg {
              fill: $inst-medium-green;
            }
          }

          .react-select__menu .react-select__option:hover,
          .react-select__menu .react-select__option--is-focused {
            background-color: $inst-medium-green;
          }

          .react-select__multi-value {
            background-color: $inst-medium-green;
            border-radius: 8px;
            overflow: hidden;
            padding: 3px;
          }

          .react-select__multi-value__label {
            padding: 3px 8px 3px 10px;
          }

          .react-select__multi-value__remove {
            width: 23px;
            height: 23px;
            align-self: center;
            color: inherit;
      
            &:hover {
              background: inherit;
            }
          }
        }
      }

      .search-form-slider {
        width: 15rem;
        margin-right: 1rem;
        margin-bottom: 2rem;

        .range-track {
          background-color: black;
          height: .2rem;
        }

        .range-thumb {
          background-color: white;
          border: 2px solid black;
          height: 20px;
          width: 20px;
          position: relative;
        }

        .range-thumb__value {
          text-align: center;
          top: 1.5rem;
          @extend .inst-text-p-small;
        }

        &.job-experience {
          .range-thumb__value {
            width: 2rem;
          }
        }
      }

      .search-form-checkbox-wrapper {
        margin-top: 1rem;

        .search-form-checkboxes {
          margin-top: .5rem;
          display: flex;

          .search-form-checkbox {
            width: 10rem;
          }
        }
      }

      .date-range {
        margin-top: 1rem;

        span, input {
          margin-right: 1rem;
        }
      }

      .step-3-text {
        margin-top: 1rem;

        input {
          width: 20rem;
        }

        textarea {
          width: 20rem;
          height: 10rem;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          border: 1px solid $inst-light-green;
          resize: none;

          &:focus {
            outline-color: $inst-green;
          }
        }
      }
    }
  }

  .nav-links {
    margin-top: 2rem;
    display: flex;

    button {
      margin-right: 2rem;

      &:first-child {
        background-color: $green;
        text-decoration: none;
        color: white;
        font-weight: 500;
        border-radius: 8px;
        padding: 1rem;
      }
    }
  }
  
  ::-webkit-input-placeholder {
   font-style: italic;
  }
  :-moz-placeholder {
   font-style: italic;  
  }
  ::-moz-placeholder {
   font-style: italic;  
  }
  :-ms-input-placeholder {  
   font-style: italic; 
  }
}