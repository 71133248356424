@import 'src/styles/variables';
@import 'src/styles/text';

.program-main-image-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0;
  height: 346px;
  overflow: hidden;

  .program-main-image-card__image {
    background-position: center;
    background-repeat: none;
    background-size: cover;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .program-main-image-card__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .program-main-image-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .text {
      width: 100%;
      padding: 2rem;

      p:last-child {
        margin-top: 1rem;
      }

      a.student-text-h2 {
        color: inherit;
        text-decoration: none;
      }
    }

    .social {
      padding: 1rem 2rem;
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        color: $dark-pink;
        @extend .student-text-p-small-italic;

        li {
          display: inline;
          margin: 0 1em;
        }
      }
    }
  }
}

.student-create-app-modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 550px;

  > * {
    margin-bottom: 1em;
  }

  .btn {
    @extend .student-text-label;
    color: white;
    background: $green;
    border: none;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    box-shadow: $student-box-shadow;
  }

  .cancel {
    @extend .student-cta-link;
    color: $dark-green;
  }
}