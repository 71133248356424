@import 'src/styles/variables';
@import 'src/styles/text';

.inst-candidate-builder {

  &__content {
    padding: 2rem calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;

    h2 {
      margin-bottom: 0.5rem;
    }

    .btn {
      @extend .inst-text-label;
      text-decoration: none;
      padding: 0.75rem 1.5rem;
      min-width: 136px;
      text-align: center;
      border-radius: 8px;
      box-shadow: $student-box-shadow;
      border: none;
      background: $inst-green;
      color: white;
    }
  }

  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .top-box {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: auto 4fr;
    column-gap: 2rem;
    align-items: flex-start;
    padding: 2rem 6rem;

    p {
      margin-bottom: 0.5rem;
    }

    button {
      margin-top: 1.5rem;
    }
  }

  .section {
    margin-top: 1rem;

    &__header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;

      .coming-soon {
        @extend .inst-text-p;
        font-style: italic;
        color: $inst-dark-pink;
      }

      button {
        background: transparent;
        border: none;
        margin-left: 1rem;
        opacity: 1;
        color: black;
        display: flex;
        align-items: center;
        @extend .inst-text-label;

        img {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.inst-builder-popup-modal {
  &.modal__bg {
    background-color: transparent;
  }

  .modal__wrapper {
    position: absolute;
    left: 285px;
    bottom: 0;
    max-width: calc(1440px - 285px);
    width: calc(100vw - 285px);
    height: calc(100vh - 100px);
    box-shadow: $student-box-shadow;
    display: grid;
    grid-template-columns: 1fr;
    background: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;
  }

  .modal__body {
    border-radius: 0;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    max-width: calc(1440px - 285px);
    width: calc(100vw - 285px);
  }

  .modal__close--wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}