@import 'src/styles/variables';
@import 'src/styles/text';

.inst-student-profile {
  padding: 1rem calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;

  .section {
    margin: 1rem 0;
  }

  .profile-block {
    padding: 2rem;

    h2 {
      @extend .inst-text-h2;
      margin: 0 1rem 1rem;
    }

    hr {
      border: none;
      background: $inst-medium-green;
      height: 2px;
      margin: 2rem 0;
    }
  }

  .block-item {
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}