@import 'src/styles/variables';

.progress-circle {
  position: relative;

  .progress-circle__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label {
      font-family: $student-font-family-text;
    }

    .percent {
      font-family: $student-font-family-headers;
    }

    &--inst {
      .percent {
        font-family: $student-font-family-text;
        font-weight: bolder;
      }
    }
  }
}