@import 'src/styles/variables';
@import 'src/styles/text';

.student-onboarding-layout-container {
  min-height: 100vh;
  width: 100vw;

  .student-onboarding-layout-container__nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    background: $light-peach;
    height: 104px;
    box-shadow: $student-box-shadow;

    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1440px;
      padding: 1.5rem;

      button {
        background: transparent;
        border: none;
        @extend .student-text-h3;
        font-weight: normal;
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .student-onboarding-layout-container__main {
    min-height: calc(100vh - 104px);
    max-width: 1440px;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: 8rem;

    .main__progress {
      padding-top: 4rem;
    }
  }
}