@import 'src/styles/variables';
@import 'src/styles/text';

.inst-profile-main-image-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem 0 1rem;
  height: 346px;
  overflow: hidden;

  &__image {
    background-position: center;
    background-repeat: none;
    background-size: cover;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .text {
      width: 100%;
      padding: 2rem;

      p.inst-text-h2 a {
        color: inherit;
        text-decoration: none;
      }

      p:last-child {
        margin-top: 1rem;
      }

      a.student-text-h2 {
        color: inherit;
        text-decoration: none;
      }
    }

    .social {
      padding: 1rem 2rem;
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        color: $dark-pink;
        @extend .inst-text-p-small;
        font-style: italic;

        li {
          display: inline;
          margin: 0 1em;
        }
      }
    }
  }
}