@import 'src/styles/variables';
@import 'src/styles/text';

.student-explore {
  margin: 0 $student-main-padding-x;
  padding-right: $student-main-padding-x;
  padding-bottom: 6rem;

  .section {
    margin: 2rem 0 1rem;

    .section__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .student-explore__search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 3rem 1rem;

    .student-favorites-link {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 60%;

      > * {
        margin: 0.5rem 0;
      }
    }
  }

  .suggested-programs {
    margin: 2em 0;
  }

  .favorites-card {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    column-gap: 2rem;
    align-items: center;
    padding: 2rem;

    .image {
      display: flex;
      justify-content: center;
    }

    .text > * {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    h2 {
      @extend .student-text-h1;
    }

    p {
      @extend.student-text-p-large;
    }

    a {
      @extend .student-text-label;
      color: $dark-pink;
      text-decoration: none;
      border: 2px solid $pink;
      box-shadow: $student-box-shadow;
      padding: 0.8rem 1.5rem;
      border-radius: 100px;
      margin-top: 1rem;
      display: inline-block;
    }
  }
}