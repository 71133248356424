@import 'src/styles/text';

.profile-add-form-button {
  background: transparent;
  border: none;
  padding: 0;
  @extend .student-text-p-small;

  display: flex;
  align-items: center;

  img {
    margin-left: 0.5rem;
  }
}