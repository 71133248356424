@import 'src/styles/variables';
@import 'src/styles/text';

.inst-tab-nav {
  width: 100%;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid $peach;

  ul {
    list-style: none;
    margin: 1px 0;
    padding: 0 $student-main-padding-x;
    @extend .inst-text-label-small;
    color: $dark-gray;

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      display: inline;
      margin: 0 2rem 0 0;
      padding: 0;
      border-bottom: 2px solid transparent;

      .active {
        color: $green;
        border-bottom: 2px solid $green; 
      }
    }
  }
}