.campus-setting-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;

  .campus-setting-card__image {
    background-position: center;
    background-repeat: none;
    background-size: cover;
  }

  .campus-setting-card__content {
    padding: 3rem 2rem;

    > p {
      margin-bottom: 1em;
    }
  }
}