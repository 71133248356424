@import 'src/styles/variables';
@import 'src/styles/text';

body {
  background-color: $almost-white;
}

.inst-layout-container {
  display: flex;
  min-height: 100vh;
  max-width: 1440px;

  .inst-layout-container__sidebar-content {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 1.5em;
  }

  .inst-layout-container__sidebar {
    background: $inst-light-peach;
    width: 285px;

    .icon {
      margin: 0 1em;
    }

    > ul {
      margin-top: 2em;
    }

    ul {
      list-style: none;
      padding-left: 0;
      @extend .inst-text-p;

      li {
        margin: 0.2em 0;

        ul {
          padding-left: 1.75rem;
          @extend .inst-text-p-small;
        }
      }
    }

    hr {
      background: $inst-medium-green;
      height: 1px;
      border: none;
      margin: 1em 0;
    }

    ul a,
    .logout,
    .accordion__button {
      width: 100%;
      border: 0;
      background: transparent;
      cursor: pointer;

      display: flex;
      align-items: center;
      border-radius: 58px;
      padding: 0.5em 0.8em;
      color: inherit;
      text-decoration: none;
      font-weight: normal;

      &.active {
        background-color: $medium-dark-peach;
      }

      &.justify {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;
          align-items: center;
        }

        .carat {
          width: 12px;
        }

        &.active,
        &.expanded {
          .carat {
            transform: rotate(180deg);
          }
        }
      }

      img {
        height: 1em;
        margin-right: 0.5em;
      }
    }

    .contact-us {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 1.5rem;
      background: $almost-white;
      margin-top: 1rem;

      p {
        align-self: flex-start;
      }

      > * {
        margin: 0.3rem 0;
      }

      a {
        background: white;
        border: 2px solid $green;
        text-decoration: none;
        padding: 0.5rem 1.25rem;
        border-radius: 100px;
        color: inherit;
        box-shadow: $student-box-shadow;
        @extend .inst-text-label;
      }
    }
  }

  .inst-layout-container__main {
    width: calc(100vw - 285px);
    max-width: calc(1440px - 285px);
    padding-bottom: 6rem;
  }
}