@import 'src/styles/text';

.student-documents__content{
    padding: 5rem 2rem 3rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > * {
        max-width: 68%;
        margin-bottom: 1rem;
    }
}