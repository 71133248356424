@import 'src/styles/variables';
@import 'src/styles/text';

.demo-pie-chart-wrapper {
  display: flex;
  align-items: center;

  .legend {
    margin-left: 3rem;

    .legend__item {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }

    .legend__color {
      width: 1.5em;
      height: 1.5em;
      margin-right: 1em;
      border-radius: 8px;
    }

    .legend__label {
      @extend .student-text-label;
    }

    &--inst {
      .legend__label {
        @extend .inst-text-label;
      }
    }
  }
}