@import 'src/styles/variables';

.student-roi-form {
  input:focus {
    outline-color: $dark-peach;
  }

  button[type="submit"] {
    margin-top: 1rem;
    background-color: $peach;
    color: $almost-black;

    &:disabled {
      background-color: $peach;
      opacity: 0.5;
    }
  }

  input[type=number] {
    margin: 0.5rem 0;
    width: 100%;
    border: 2px solid $peach;
    border-radius: 51px;
    font-family: $student-font-family-text;
    min-height: 48px;
    padding: 0 1rem;
    -moz-appearance: textfield;
  
    &::placeholder {
      font-style: italic;
    }
  
    &.money-field {
      padding-left: 30px;
    }
  }

  .placeholder-symbol {
    color: gray;
    position: absolute;
    top: 40px;
    left: 20px;
    font-style: italic;
  
    &.percent {
      left: 55px;
    }
  }

  .field {
    position: relative;
  
    .form-label {
      display: flex;
      position: relative;
  
      img {
        height: 20px;
        margin-left: 1rem;
        cursor: pointer;
      }
  
      .tooltipContainer {
        margin-left: 2rem;
      }
  
      .tooltip {
        position: absolute;
        height: auto;
        width: 20rem;
        z-index: 1;
        background: white;
        padding: 1.5rem;
        border-radius: 8px;
        font-family: $student-font-family-text;
        box-shadow: 0 0 15px 0 rgba(44, 26, 11, 15%);
  
        a {
          color: black;
        }
      }
    }  
  }
}
