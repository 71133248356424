@import 'src/styles/text';

.student-most-compatible {
  img {
    margin-left: 0.3rem;
    position: relative;
    top: 1px;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }
}

.student-most-compatible-popup {
  max-width: 240px;
  padding: 1rem;
  @extend .student-text-p-small;
}