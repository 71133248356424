@import 'src/styles/variables';
@import 'src/styles/text';

.school-profile-page {

  .section {
    margin: 1rem 0;

    .section__header {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        
        > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }

    .faq-header {
      background: $medium-peach;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;

      &--inst {
        background: $inst-light-green;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    column-gap: 1rem;
  }

  .grid-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1rem;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .padded {
    padding: 2rem;
  }

  .ethnic-group-chart {
    @extend .student-text-label;
    > div {
      max-width: 600px;
      display: grid;
      grid-template-columns: 1.8fr 2.5fr 0.5fr;
      column-gap: 1em;

      &:not(:last-child) {
        margin-bottom: 2em;
      }
    }

    &--inst {
      @extend .inst-text-label;
    }
  }

  .programs-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .student-cta-link {
      color: $dark-pink;
      margin-left: 1rem;
    }

    &--inst {

      .inst-cta-link {
        color: $inst-dark-pink;
      }
      
      .inst-search-input {
        display: flex;
        padding: 0;

        img {
          opacity: 0.5;
          margin-left: 1em;
        }

        input {
          flex: 1;
          border: none;
          background: transparent;
          padding: 0 0.5em;
          @extend .inst-text-p;

          &::placeholder {
            font-style: italic;
          }
      
          &:focus {
            outline: none;
          }
        }

        button {
          border: none;
          background: transparent;
          border-left: 1px solid $peach;
          margin: 0.5rem 0;
          padding: 0 1rem;
          color: $peach;
          font-size: 1.5em;
      
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .programs-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .profile-illustration-box {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    column-gap: 4rem;
    align-items: center;

    h3 {
      margin-bottom: 1rem;
    }

    .link,
    .link a {
      @extend .student-text-p;
      color: $dark-pink;
      text-decoration: underline;
      margin: 1rem 0;
    }

    &--inst {
      .link,
      .link a {
        @extend .inst-text-p;
        color: $inst-dark-pink;
      }
    }
  }

  .faculty-research {
    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }

    > * {
      margin-bottom: 0.5rem;
    }
    h3 {
      @extend .student-text-label;
    }

    p {
      @extend .student-text-p;

      &:first-of-type {
        font-style: italic;
      }
    }

    &--inst {
      h3 {
        @extend .inst-text-label;
      }

      p {
        @extend .inst-text-p;
      }
    }
  }

  .faq-accordion {
    margin-bottom: 3rem;

    .accordion__item,
    .accordion__item--expanded {
      border-bottom: 2px solid $peach;
    }

    .accordion__item--expanded img.open {
      display: none;
    }

    .accordion__item img.close {
      display: none;
    }

    .accordion__button {
      @extend .student-text-h2;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        margin-left: 2rem;
      }
    }

    .accordion__panel {
      @extend .student-text-p;
      padding: 1rem 0 2rem;
    }

    &--inst {
      .accordion__item,
      .accordion__item--expanded {
        border-color: $inst-light-green;
      }

      .accordion__button {
        @extend .inst-text-h2;
      }

      .accordion__panel {
        @extend .inst-text-p;
      }
    }
  }

  .inst-coming-soon-button {
    display: flex;
    align-items: center;
    @extend .inst-text-p-small;
    background: transparent;
    border: none;

    img {
      margin-left: 0.5rem;
    }
  }
}