.program-recs-carousel {

  .program-rec-card {
    margin: 1rem;
    height: 300px;
    overflow: hidden;
    display: grid;
    grid-template-rows: 150px auto;


    .program-rec-card__image {
      height: 150px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      padding: 0.75rem 1rem;
    }

    .program-rec-card__content {
      padding: 0.75rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3, p {
        margin: 0;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      div {
        div {
          display: flex;
          justify-content: flex-end;
          margin: 0.5rem 0 0;
        }
      }
    }
  }
}