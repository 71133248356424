@import 'src/styles/variables';
@import 'src/styles/text';

.student-apps {
  padding-bottom: 6rem;

  .student-apps__content {
    padding: 0 $student-main-padding-x;
    margin-right: $student-main-padding-x;
  }

  .section {
    margin: 2rem 0 1rem;

    h2 {
      margin-bottom: 1rem;
    }
  }

  .add-app {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    @extend .student-text-p;

    img {
      margin-right: 0.5rem;
    }
  }

  .progress {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1em;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
    }

    .in-progress,
    .submitted {
      display: flex;
      align-items: center;
      border-radius: 58px;
      padding: 0.2em 1.7em;
      margin: 0.5rem 0;

      span {
        margin-right: 0.25em;
      }
    }

    .in-progress {
      margin-top: 1rem;
      background: $light-yellow;
      color: $dark-yellow;
    }

    .submitted {
      background: $light-green;
      color: $green;
    }

    .overview-content {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      column-gap: 1rem;
      align-items: center;

      p + p {
        margin-top: 1em;
      }

      .overview-content__image {
        display: flex;
        justify-content: center;
      }
    }


  }

  .placeholder-link {
    width: 100%;
    border: 2px solid $light-blue;
    border-radius: 88px;
    padding: 1.8em 2em;
    color: $dark-blue;
  
    text-decoration: none;
    color: inherit;
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .accent {
      @extend .student-text-p-small-italic;
    }
  }

  .accordion__item--expanded {
    .accordion__button img {
      transform: rotate(180deg);
    }
  }

  .accordion__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $peach;
  }

  .accordion__panel {
    padding: 1rem 0;
  }

  .apps-list--in-progress {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
  
  .apps-list--submitted {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .accent {
    color: $blue;
  }
}

.student-add-app-modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;

  h1 {
    @extend .student-text-h1;
  }
}