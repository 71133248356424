@import 'src/styles/variables';
@import 'src/styles/text';

.student-program-profile-web-link {
  display: flex;
  align-items: center;
  color: $dark-pink;
  @extend .student-cta-link;

  img {
    margin-left: 0.5rem;
  }
}