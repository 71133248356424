@import '../../../styles/variables.scss';
@import '../../../styles/_text.scss';
@import 'src/styles/mixins';

.list-purchase-review {
  margin-bottom: 2rem;
  
  input[type=checkbox] {
    cursor: pointer;
    position: relative;
    top: 0.35rem;
    @include customCheckboxStyles($green);
    width: 23px;
    
    &:checked {
      &:after {
        left: 3px;
      }
    }
  }
  
  input[type=radio] {
    cursor: pointer;
    position: relative;
    top: 0.7rem;
    @include customRadioStyles($green);
    
    &:checked {
      &:after {
        left: 3px;
      }
    }
  }

  .form-label {
    margin-bottom: 0.5rem;
  }

  button {
    display: flex;
    background: none;
    border: none;
    text-decoration: underline;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      position: relative;
      top: 0.15rem;
    }
  }

  input[type="text"] {
    background: #EFEFF1;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    width: 12rem;
  }

  .green-button {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-decoration: none;
    background: $green;
    color: white;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 8px;

    &:disabled {
      background: grey;
    }
  }

  .section {
    margin-bottom: 1rem;

    .section-header {
      width: 100%;
      background: $inst-light-green;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      border-radius: 8px;

      span.left {
        font-weight: 700;
        margin-right: 1rem;
      }
    }

    .section-inner {
      padding: 0.5rem 1rem;

      .checkbox-wrapper {
        display: flex;
        align-items: flex-start;

        input {
          margin-right: 0.75rem;
          position: relative;
          top: 0.25rem;
        }
      }

      .radio-wrapper {
        input {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .dropdown-wrapper {
        display: flex;

        .dropdown {
          margin-right: 4rem;

          label {
            padding-bottom: 2rem;
          }

          .student-survey-select {
            width: 25rem;
          }
        }
      }

      .price-details {
        display: flex;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $inst-light-green;

        .price-detail {
          display: flex;
          flex-direction: column;
          margin-right: 4rem;
          width: 18rem;

          label {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .promo-code {
        padding-top: 1rem;

        .promo-code-apply {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;

          button {
            margin-left: 2rem;
          }
        }
      }
    }
  }
}