@import '../../../styles/_variables.scss';

.search-bar-container {
  margin: 2rem 25rem 0rem 25rem;
  position: relative;

  .search-bar {
    border: 2px solid $peach;
    border-radius: 51px;
    height: 2.5rem;
    width: 25rem;
    padding-left: 2.5rem;

    &::placeholder {
      font-style: italic;
    }
  }

  .search-bar-icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }
}

.filter-container {
  display: flex;
  width: 99%;
  justify-content: flex-end;
  position: relative;

  .filter-button {
    display: flex;
    justify-content: flex-end;
    width: 12rem;
    align-items: center;
    margin-right: 2rem;
    color: $dark-pink;
    padding-top: 1rem;
    border: none;
    background: none;

    img {
      margin-left: 0.5rem;
      height: 0.5rem;

      &.reverse {
        transform: rotate(180deg);
      }
    }
  }

  .filter-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: white;
    border-radius: 16px;
    box-shadow: 0 0 15px 0 rgba(44, 26, 11, 15%);
    padding: 1rem;
    top: 3rem;
    right: 2rem;
    height: 13rem;
    width: 15rem;

    button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      height: 4rem;
    }
  }
}

.articles-container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 2em;
  row-gap: 2em;
  width: 100%;
  padding: 1em 2em;

  a {
    text-decoration: none;
  }

  .articles-card {
    max-width: 31%;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 0 15px 0 rgba(44, 26, 11, 15%);

    img {
      border-radius: 16px 16px 0 0;
      height: 14rem;
      object-fit: cover;
    }

    .articles-card-content {
      padding: 1.5rem 1rem;
      color: black;

      h2 {
        margin-bottom: 1rem;
      }
    }
  }
}
