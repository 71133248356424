@import 'src/styles/variables';
@import 'src/styles/text';

.inst-app-card {
  text-decoration: none;
  color: inherit;
  padding: 1rem;

  h2 {
    @extend .inst-text-h4;
    margin-bottom: 1rem;

    a {
      color: inherit;
      text-decoration: none;
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}