@import 'src/styles/variables';
@import 'src/styles/text';

.inst-recruiting-landing {

  &__content {
    padding: 2rem calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;
  }

  .grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;

    .tip-card {
      padding: 6rem 2rem 2rem;
      background: $inst-light-green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      h2 {
        @extend .inst-text-label;
        color: $inst-dark-pink;
        margin-bottom: 0.3em;
      }

      p {
        @extend .inst-text-p;
      }
    }

    .link-card {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 523px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin-bottom: 1rem;
          height: 161px;
        }
      }

      h2 {
        align-self: flex-start;
        margin-bottom: 0.3em;
        @extend .inst-text-h3;
      }
  
      p {
        @extend .inst-text-p-large;
      }
    }

    .btn,
    .btn--outline {
      @extend .inst-text-label;
      text-decoration: none;
      padding: 0.75rem 2rem;
      min-width: 136px;
      text-align: center;
      border-radius: 8px;
      box-shadow: $student-box-shadow;
      border: 2px solid $inst-green;
    }

    .btn {
      background: $inst-green;
      color: white;
    }

    .btn--outline {
      background: white;
      color: $inst-dark-green;
      border-color: $inst-dark-green;
    }
  }
}