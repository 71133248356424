@import 'src/styles/variables';
@import 'src/styles/text';

.inst-search {
  padding: 2rem calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;

  .top-box {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: auto 4fr;
    column-gap: 2rem;
    align-items: center;
    padding: 2rem 6rem;

    .search-box {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      > * {
        margin-right: 1rem;
      }
    }
  }

  .grid {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .btn {
    @extend .inst-text-label;
    text-decoration: none;
    padding: 0.7rem 2rem;
    text-align: center;
    border-radius: 8px;
    border: none;
    box-shadow: $student-box-shadow;
    background: $inst-green;
    color: white;
  }
}