@import 'src/styles/variables';
@import '../../../styles/_text.scss';

.student-resources-page {
  padding-bottom: 6rem;

  .back-button-container {
    top: 96px;
    padding-bottom: 1rem;
  
    a {
      @extend .student-text-h3;
      background: none;
      border: none;
      display: flex;
      color: black;
      padding-left: 2rem;
  
      img {
        margin-right: 1rem;
        padding-top: 0.25rem;
      }
    }
  }

  .student-resources-tabs {
    ul {
      display: flex;
      list-style: none;
      border-bottom: 1px solid $peach;
  
      li {
        font-family: $student-font-family-headers;
        margin-left: 2em;
        padding-bottom: 0.3em;
        color: $dark-gray;
        text-transform: uppercase;
  
        a {
          text-decoration: none;
          color: $dark-gray;
          padding-bottom: 0.25rem;
  
          &.active {
            color: $green;
            box-shadow: 0 3px $green;
          }
        }
  
        span {
          text-transform: none;
          font-weight: normal;
        }
      }
    }
  }
}
