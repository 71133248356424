@import 'src/styles/variables';
@import 'src/styles/text';
@import 'src/styles/mixins';

.student-profile-modal-form {
  padding: 1rem 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    max-width: 415px;
    margin: 1.5rem auto 0;

    p {
      margin: 0 0 1.5rem;
    }

    label, legend {
      @extend .student-text-label;
    }

    fieldset {
      border: none;
      padding: 0;
    }

    .field + .field,
    .flex,
    .flex + .field {
      margin-top: 1.5rem;
    }

    .flex > .field + .field {
      margin: 0;
    }

    .field {
      .error-msg {
        @extend .student-text-p-small;
        color: $error-red;
      }

      &.error {
        input[type=text],
        input[type=date] {
          border-color: $error-red;
        }
      }

      &.center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      > * {
        width: 100%;
      }

      .radio {
        margin-right: 1rem;
      }
    }

    .radio {
      display: flex;
      align-items: center;
      @extend .student-text-p;
      text-transform: none;

      input[type=radio] {
        margin-right: 0.5rem;
        @include customCheckboxStyles($green);
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      @extend .student-text-p;
      text-transform: none;

      input[type=checkbox] {
        margin-right: 0.5rem;
        @include customCheckboxStyles($green);
      }
    }

    label > input[type=text],
    label > input[type=date],
    textarea {
      margin: 0.5rem 0;
      width: 100%;
      border: 2px solid $light-green;
      border-radius: 51px;
      font-family: $student-font-family-text;
      min-height: 38px;
      padding: 0 1rem;
    
      &:focus {
        border-color: $green;
        outline: 0;
      }

      &::placeholder {
        font-style: italic;
      }
    }

    textarea {
      height: 130px;
      resize: none;
      border-radius: 32px;
      padding: 1rem;
    }

    .student-profile-select {
      @include customStudentMultiSelectStyles($green, $light-green);
    }

    .dotted-box {
      margin: 1.5rem 0;
      border: 2px dashed $light-green;
      background: white;
      border-radius: 16px;
      min-height: 190px;
      padding: 2rem;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p + p {
        margin: 1rem 0;
      }
    }

    .profile-form-button {
      @extend .student-text-label;
      background: $green;
      color: white;
      border: none;
      border-radius: 100px;
      padding: 0.5rem 1.5rem;
      min-width: 146px;
      box-shadow: $student-box-shadow;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}