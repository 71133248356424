@import 'src/styles/variables';
@import 'src/styles/text';
@import 'src/styles/mixins';

.search-filter-container {
  width: 100%;

  .student-cta-link {
    color: $dark-pink;
    cursor: pointer;
    margin-left: 1rem;
  }

  .search {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    input {
      width: 300px;
    }

    .flex {
      display: flex;
      align-items: center;
    }

    button[type=submit] {
      @extend .student-text-label;
      margin-top: 2rem;
      background: $pink;
      color: white;
      border: none;
      padding: 0.8rem 2rem;
      min-width: 175px;
      border-radius: 100px;
      box-shadow: $student-box-shadow;
    }
  }
}

.search-filter-list {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  width: 500px;
  max-width: 500px;

  .accordion {
    padding: 1rem 2rem;
  }

  .accordion__item:not(:last-child) {
    border-bottom: 1px solid $light-pink;
  }

  .accordion__button {
    padding: 1em 0;
    cursor: pointer;
    @extend .student-text-h3;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &[aria-expanded="true"] {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .accordion__panel {
    padding-bottom: 1em;

    fieldset {
      border: none;
      padding: 0;

      &.location > div {
        margin: 1rem 0;

        input {
          width: 100%;
        }
      }

      input[type=text] {
        border: 2px solid $light-pink;
        border-radius: 51px;
        @extend .student-text-p-italic;
        padding: 5px 8px;
        margin-top: 0.5rem;
      }

      .checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.5em 0 0.5em 1em;
        @extend .student-text-p;

        input[type=checkbox],
        input[type=radio] {
          @include customCheckboxStyles($pink);
        }
      }
    }
  }

  .buttons {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 128px;
    background-color: white;
    padding: 0 2rem;
    box-shadow: $student-box-shadow;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .student-cta-link {
      color: $dark-pink;
      margin-left: 2rem;
    }

    .search-button {
      background-color: $pink;
      border: none;
      color: $almost-white;
      text-decoration: none;
      padding: 1rem 3rem;
      border-radius: 100px;
    }

    .coming-soon {
      @extend .student-text-p-small-italic;
      color: $dark-pink;
      margin-left: 1rem;
    }
  }

  .filter-states {
    margin-top: 0.5rem;
    font-family: $student-font-family-text;

    .react-select__control {
      border: 2px solid $light-pink;
      border-radius: 51px;

      &:focus-within,
      &:focus {
        border-color: $pink;
        outline: 0;
      } 

      .react-select__input {
        &:focus {
          outline: 0;
        }
      }

      .icon {
        margin-left: 0.25em;
        opacity: 0.5;
        transform: scale(0.8);
      }
    }

    .react-select__placeholder {
      font-style: italic;
    }

    .react-select__indicator-separator {
      background-color: $light-pink;
    }

    .react-select__indicator {
      svg {
        fill: $light-pink;
      }
    }

    .react-select__menu .react-select__option:hover,
    .react-select__menu .react-select__option--is-focused {
      background-color: $light-pink;
    }

    .react-select__multi-value {
      background-color: $light-pink;
      border-radius: 32px;
      overflow: hidden;
      padding: 3px;
    }

    .react-select__multi-value__label {
      padding: 3px 8px 3px 10px;
    }

    .react-select__multi-value__remove {
      border-radius: 50%;
      width: 23px;
      height: 23px;
      align-self: center;
      background: $pink;
      color: white;
    }
  }
}