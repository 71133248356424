@import 'src/styles/variables';

.app-card--submitted {
  padding: 1em;

  .status {
    display: flex;
    align-items: center;
    border-radius: 58px;
    padding: 0.5em 1.7em;
    margin: 1rem 0 0;
    background: $medium-peach;
    width: max-content;
  }
}