@import 'src/styles/variables';
@import 'src/styles/text';

.inst-messaging-popup {
  position: fixed;
  right: 4rem;
  width: 513px;
  bottom: 0;
  box-shadow: $student-box-shadow;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  z-index: 2000;

  @media screen and (min-width: 1440px) {
    right: calc(100vw - 1440px + 4rem);
  }

  &--message {
    right: calc(5rem + 513px);

    @media screen and (min-width: 1440px) {
      right: calc(100vw - 1440px + 5rem + 513px);
    }
  }

  .accordion,
  .message-box {
    width: 513px;
    background: $almost-white;

    .count {
      background: $inst-dark-peach;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .accordion__button,
  .message-box__heading {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $inst-light-peach;
    padding: 1rem 1.5rem;
    @extend .inst-text-h2;

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        margin-right: 1rem;
      }
    }

    button {
      background: transparent;
      border: none;
      padding: 0;
    }

    .count {
      height: 32px;
      width: 32px;
      margin-left: 1rem;
    }
  }

  .message-box__content {
    padding: 1rem 1.5rem;
    height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .date {
      @extend .inst-text-p-small;
      color: $dark-gray;
      text-align: center;
    }

    .messages {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex: 1 0 0;

      .message {
        display: flex;
        align-items: flex-end;
        width: 70%;
        margin: 1rem 0 0;

        .name {
          @extend .inst-text-p-small;
          color: $dark-gray;
        }

        .body {
          border-radius: 8px;
          padding: 0.5rem;
          @extend .inst-text-p;
        }

        .img {
          min-width: 24px;
        }

        &.message--left {
          align-self: flex-start;

          .img {
            margin-right: 0.5rem;
          }

          .body {
            background-color: $light-gray;
          }
        }

        &.message--right {
          align-self: flex-end;

          .img {
            margin-left: 0.5rem;
          }

          .body {
            background-color: $light-peach;
          }
        }
      }
    }

    .input {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        margin-left: 0.5rem;
      }

      input, input[disabled] {
        opacity: 1;
        border: 2px solid $inst-medium-peach;
        border-radius: 8px;
        @extend .inst-text-p-small;
        font-style: italic;
        flex: 1;
        background: $almost-white;
        padding: 0.5rem;
      }
    }
  }

  .accordion__item--expanded .accordion__button img {
    transform: rotate(180deg);
  }

  .accordion__panel {
    padding: 1rem 1.5rem;
    height: 400px;

    .message {
      background: transparent;
      border: none;
      text-align: left;
      display: grid;
      grid-template-columns: 40px auto 40px;
      column-gap: 1rem;
      @extend .inst-text-p-small;

      margin: 1rem 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid $peach;

      p:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .gray {
        color: $dark-gray;
      }

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }

      .count {
        display: flex;
        width: 24px;
        height: 24px;
        @extend .inst-text-p;
        font-weight: bold;
      }
    }
  }
}