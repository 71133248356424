@import '../../../styles/variables.scss';
@import '../../../styles/_text.scss';

.purchase-list-confirmation {
  .back-button {
    display: flex;
    background: none;
    border: none;
    text-decoration: underline;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      position: relative;
      top: 0.15rem;
    }
  }

  .confirmation-details {
    padding: 1rem;
    background: $inst-light-green;
    border-radius: 4px;
  }

  .orders-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .green-button {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      text-decoration: none;
      background: $green;
      color: white;
      font-weight: 500;
      padding: 0.75rem 1rem;
      border-radius: 8px;
      border: none;
      margin-top: 2rem;
    }
  }

  .order-history-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .shadow-card {
      width: 21rem;

      .order-card {
        .order-card-top {
          border-radius: 16px 16px 0 0;
          padding: 0.6rem 1rem;
          height: 3rem;
          background: $medium-green;
          display: flex;
          justify-content: space-between;
        }

        .order-card-bottom {
          padding: 1.5rem 1.5rem;
          display: flex;
          flex-direction: column;

          .description {
            display: flex;
            flex-direction: column;

            > .inst-text-label {
              color: grey;
            }
          }

          .links {
            margin-top: 1rem;

            .order-button {
              background: white;
              border: 2px solid $green;
              color: $green;
              border-radius: 8px;
              text-transform: uppercase;
              padding: .75rem 1.5rem;
              margin-right: 1.5rem;
            }

            .details-button {
              background: none;
              border: none;
              text-decoration: underline;
              color: $dark-pink;
            }
          }
        }

        .order-card-bottom {
          padding: 2rem 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 17.5rem;

          .info {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            > .inst-text-p {
              color: grey;
            }
          }

          .recurring-purchase {
            display: flex;
            width: 70%;
            padding: 0.5rem 0.5rem;
            align-items: center;
            background: $lime;
            border-radius: 16px;
            margin-bottom: 1rem;

            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}