@import 'src/styles/text';

@mixin customRadioStyles($color) {
  height: 18px;
  width: 18px;
  appearance: none;
  border-radius: 50%;
  border: 2px solid $color;

  &:checked {    
    position: relative;
    background-color: $color;

    &:after {
      content: '\00AC';
      display: block;
      text-align: center;
      color: white;
      transform: scale(1.5) rotate(132deg);
      position: absolute;
      left: 5px;
      top: -2px;
    }
  }
}

@mixin customCheckboxStyles($color) {
  height: 18px;
  width: 18px;
  appearance: none;
  border-radius: 2px;
  border: 2px solid $color;

  &:checked {    
    position: relative;
    background-color: $color;

    &:after {
      content: '\00AC';
      display: block;
      text-align: center;
      color: white;
      transform: scale(1.7) rotate(132deg);
      position: absolute;
      left: 5px;
      top: -4px;
    }
  }
}

@mixin customStudentMultiSelectStyles($primaryColor, $lightColor) {
  margin: 0.5rem 0;
  font-family: $student-font-family-text;

  .react-select__control {
    border: 2px solid $lightColor;
    border-radius: 51px;
    padding-left: 0.5rem;

    &:focus-within,
    &:focus {
      border-color: $primaryColor;
      outline: 0;
      box-shadow: none;
    } 

    .react-select__input {
      &:focus {
        outline: 0;
      }
    }

    .icon {
      margin-left: 0.25em;
      opacity: 0.5;
      transform: scale(0.8);
    }
  }

  .react-select__placeholder {
    font-style: italic;
  }

  .react-select__indicator-separator {
    background-color: $lightColor;
  }

  .react-select__indicator {
    svg {
      fill: $lightColor;
    }
  }

  .react-select__menu .react-select__option--is-hovered,
  .react-select__menu .react-select__option--is-focused,
  .react-select__menu .react-select__option--is-selected {
    background-color: $lightColor;
    color: black;
  }

  .react-select__multi-value {
    background-color: $lightColor;
    border-radius: 32px;
    overflow: hidden;
    padding: 3px;
  }

  .react-select__multi-value__label {
    padding: 3px 8px 3px 10px;
  }

  .react-select__multi-value__remove {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    align-self: center;
    background: $primaryColor;
    color: white;
  }

  &.error {
    .react-select__control {
      border-color: $error-red;
    }
  }
}