@import 'src/styles/variables';
@import 'src/styles/text';

.inst-recommendations {

  &__content {
    padding: 0 calc(2 * #{$student-main-padding-x}) 3rem $student-main-padding-x;
  }

  h2 {
    margin-bottom: 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .tip-card {
    padding: 2rem;
    background: $inst-light-green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 1rem;
    margin-bottom: 1rem;

    h2 {
      @extend .inst-text-label;
      color: $inst-dark-pink;
      margin-bottom: 0.3em;
    }

    p {
      @extend .inst-text-p;
    }

    .btn--outline {
      @extend .inst-text-label;
      text-decoration: none;
      padding: 0.75rem 2rem;
      text-align: center;
      border-radius: 8px;
      box-shadow: $student-box-shadow;
      border: 2px solid $inst-dark-green;
      background: white;
      color: $inst-dark-green;
      margin-top: 1rem;
    }
  }
}