@import 'src/styles/variables';

.survey-link {
  width: 100%;
  border: 2px solid $lime;
  border-radius: 88px;
  padding: 1.5em 2em;
  margin: 1em 0;

  text-decoration: none;
  color: inherit;
  
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .student-text-label {
    color: $dark-pink;
    margin-bottom: 2rem;

    > span {
      text-transform: none;
    }
  }
}