@import 'src/styles/variables';

.data-value-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.centered {
    align-items: center;
  }

  padding: 3em;
  background: $medium-peach;
  color: $dark-pink;
  border-radius: 16px;

  p:first-child {
    margin-bottom: 1rem;
  }
}