@import '../../../styles/variables.scss';
@import '../../../styles/_text.scss';

.purchase-list-review {
  .back-button {
    display: flex;
    background: none;
    border: none;
    text-decoration: underline;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      position: relative;
      top: 0.15rem;
    }
  }

  .price-details {
    display: flex;
    padding-bottom: 1.5rem;
    margin-top: 1rem;

    .price-detail {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;
      width: 18rem;

      span {
        margin-bottom: 0.5rem;
      }

      label {
        display: flex;
        flex-direction: column;

        input[type="text"] {
          background: #EFEFF1;
          border: 1px solid lightgrey;
          border-radius: 4px;
          padding: 0.5rem 1rem;
          width: 12rem;
        }
      }
    }
  }

  .order-number {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 0.5rem;
    }

    input[type="text"] {
      background: #EFEFF1;
      border: 1px solid lightgrey;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      width: 22rem;
    }
  }

  .green-button {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-decoration: none;
    background: $green;
    color: white;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    margin-top: 2rem;
  }
}