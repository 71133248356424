@import 'src/styles/variables';
@import 'src/styles/text';

.student-search-results {
  margin: 0 $student-main-padding-x;
  padding-right: $student-main-padding-x;
  padding-bottom: 8rem;

  .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-container {
    margin: 2em 0;
    max-width: 500px;
  }

  .results-container {

    .section__header {
      color: $dark-pink;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-results__sort {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $dark-pink;
      margin: 1rem 0;
  
      .coming-soon {
        @extend .student-text-p-small-italic;
      }
  
      .sort {
        @extend .student-text-h3;
        display: flex;
        align-items: center;
        margin-left: 1rem;
  
        img {
          margin-left: 0.5rem;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}