@import 'src/styles/variables';

.search-input {
  display: flex;
  border: 2px solid $light-pink;
  background-color: $almost-white;
  border-radius: 51px;
  padding: 0;

  &:focus-within {
    border: 2px solid $pink;
  }

  img {
    opacity: 0.5;
    margin-left: 1em;
  }

  input {
    border: none;
    height: 48px;
    background: transparent;
    padding: 0 0.5em;
    flex: 1;

    &::placeholder {
      font-style: italic;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    background: transparent;
    border-left: 1px solid $light-pink;
    margin: 0.5rem 0;
    padding: 0 1rem;
    color: $light-pink;
    font-size: 1.5em;

    display: flex;
    align-items: center;
    justify-content: center;
  }

}