@import 'src/styles/variables';
@import 'src/styles/text';

.student-add-button {
  display: flex;
  align-items: center;
  @extend .student-cta-link;
  color: $almost-black;
  
  img {
    margin-right: 0.5rem;
  }
}