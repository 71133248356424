@import 'src/styles/variables';
@import 'src/styles/text';

.inst-application {
  padding-bottom: 3rem;

  &__sticky {
    position: sticky;
    top: 0;
    width: 100%;
    background: $almost-white;
    z-index: 1;
  }

  &__header {
    background: $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    a {
      color: inherit;
      text-decoration: none;
    }

    > div {
      display: flex;
      align-items: center;
      padding: 1rem calc(2 * #{$student-main-padding-x}) 1rem $student-main-padding-x;
    }

    .left > * {
      margin-right: 1rem;
    }

    .right > * {
      margin-left: 1rem;

      &.review,
      &.decision {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        @extend .inst-text-label;

        img {
          margin: 0 0.5rem;
        }
      }

      &.tests {
        @extend .inst-cta-link;
      }
    }

    h2 {
      @extend .inst-text-h2;
    }
  }

  &__document {
    display: flex;
    align-items: center;
    background: $light-gray;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
    max-width: 416px;
    @extend .inst-text-p-small;

    img {
      margin-right: 1rem;
    }
  }

  .inst-app-buttons {
    margin-top: 2rem;
    padding-right: calc(2 * #{$student-main-padding-x});
    display: flex;
    justify-content: flex-end;

    button {
      @extend .inst-cta-link;
      color: $inst-dark-pink;
    }
  }

  .inst-app-accordion {
    margin-top: 1em;
    padding: 0 calc(2 * #{$student-main-padding-x}) 0 $student-main-padding-x;
  
    .accordion__item,
    .accordion__item--expanded {
      border-radius: 16px;
      margin-bottom: 1rem;

      .coming-soon {
        @extend .inst-text-p;
        font-style: italic;
        color: $inst-dark-pink;
        margin-left: 0.5rem;
      }
    }
  
    .accordion__item {
      border: 2px solid $inst-medium-green;

      .coming-soon {
        display: none;
      }
    }
  
    .accordion__item--expanded {
      border: 2px solid $inst-green;
    }
  
    .accordion__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 1.5rem;
  
      span {
        @extend .inst-text-h2;
      }
  
      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
  
        img {
          margin-left: 2rem;
        }
      }
    }
  
    .accordion__item--expanded {
      .accordion__button img.carat {
        transform: rotate(180deg);
      }
    }
  
    .accordion__panel {
      padding: 0 1.5rem 1rem;

      p {
        @extend .inst-text-p-large;

        span:not(.coming-soon) {
          @extend .inst-text-label-small;
          color: $dark-gray;
        }

        span.coming-soon {
          font-size: inherit;
          color: inherit;
          margin-left: 0;
          font-style: italic;
        }
      }

      p:not(:last-child),
      .dotted-box {
        margin-bottom: 2rem;
      }

      h2 {
        @extend .inst-text-h4;
        margin-bottom: 1rem;

        .coming-soon {
          @extend .inst-text-p;
          font-style: italic;
          color: $inst-dark-pink;
          margin-left: 0.5rem;
        }
      }

      hr {
        border: none;
        height: 2px;
        background: $inst-light-green;
        margin-bottom: 2rem;
      }

      .dotted-box {
        border-radius: 1rem;
        border: 2px dashed $inst-medium-green;
        padding: 2rem;
        width: 416px;
      }

      .rec {
        margin-bottom: 1.5rem;
      }

      .grid {
        max-width: 700px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.inst-app-recommended-tests {
  padding: 2rem;

  > * + * {
    margin: 1rem 0;
  }

  h1 {
    @extend .inst-text-h1;
  }

  h2 {
    @extend .inst-text-h2;
  }

  p {
    @extend .inst-text-p-small;
  }

  hr {
    border: none;
    height: 2px;
    background: $inst-light-green;
  }

  .coming-soon {
    @extend .inst-text-p;
    font-style: italic;
    color: $inst-dark-pink;
  }

  .flex {
    button {
      margin: 0 1rem 0 0;
    }
  }
}

.inst-app-decision-popup {
  padding: 0.5rem 1rem;

  p {
    @extend .inst-text-p;
    line-height: 2.5;
  }

  .coming-soon {
    font-style: italic;
    color: $inst-dark-pink;
  }
}