@import 'src/styles/variables';
@import 'src/styles/text';

.inst-switch-program {
  min-height: 100vh;
  width: 100vw;

  &__nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    background: $inst-light-peach;
    height: 104px;

    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1440px;
      padding: 1.5rem;

      button {
        background: transparent;
        border: none;
        @extend .student-text-h3;
        font-weight: normal;
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__main {
    min-height: calc(100vh - 104px);
    max-width: 1440px;
    padding: 4rem 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;

    button {
      background: transparent;
      border: none;
      width: 100%;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 2rem;
      }
    }

    .program-card {
      width: 100%;
      min-height: 200px;

      display: flex;
      justify-content: center;
      align-items: center;

      @extend .inst-text-h2;
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;

      img {
        margin-top: 5rem;
      }
    }
  }
}