@import 'src/styles/variables';
@import 'src/styles/text';
@import 'src/styles/mixins';

.privacy-contents {
  width: max-content;
  padding: 0.5rem;

  p {
    border-bottom: 1px solid $light-green;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
  }

  .checkbox {
    width: 80%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .student-text-p-small;

    input[type=checkbox] {
      @extend .student-text-p;
      @include customCheckboxStyles($dark-gray);
    }
  }
}