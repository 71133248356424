@import 'src/styles/variables';

.student-favorites-link {
  display: flex;
  justify-content: flex-end;

  .student-cta-link {
    display: flex;
    align-items: center;
    color: $dark-pink;

    img {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
    }
  }
}