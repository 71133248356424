@import 'src/styles/text';

.student-program-popup-menu__dots {
  background: transparent;
  border: none;
  margin-left: 1rem;
}

.student-program-popup-menu {
  padding: 0;
  button {
    background: transparent;
    border: none;
    @extend .student-text-p;
  }

  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
      padding: 0.5rem 1rem;
    }
  }
}