@import 'src/styles/variables';
@import 'src/styles/text';

.student-favorites {
  margin: 0 $student-main-padding-x;
  padding-right: $student-main-padding-x;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .student-favorites__link {
    display: flex;
  }

  .student-favorites__sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $dark-pink;
    margin: 1rem 0;

    .coming-soon {
      @extend .student-text-p-small-italic;
    }

    .sort {
      @extend .student-text-h3;
      display: flex;
      align-items: center;
      margin-left: 1rem;

      img {
        margin-left: 0.5rem;
        position: relative;
        top: 2px;
      }
    }
  }

  .empty-placeholder {
    @extend .student-text-h2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 600px;
    margin: 3rem auto;

    > * {
      margin: 1rem 0;
    }

    a {
      @extend .student-text-label;
      border: 2px solid $pink;
      color: $dark-pink;
      background: white;
      text-decoration: none;
      height: 48px;
      border-radius: 100px;
      padding: 0.5rem 1rem;
      box-shadow: $student-box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}